import { t } from '@/locales'
import { Simulation } from '@/model/Simulation'
import { User } from '@/model/User'
import router from '@/router'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'

import { saveAs } from 'file-saver'

export const idToString = (_id: string): string => {
  if (_id === undefined || _id === null || _id === '') {
    return _id
  }

  let res = ''
  res += _id.toString().slice(0, 2)
  res += ' ' + _id.toString().slice(2, 4)
  res += ' ' + _id.toString().slice(4, 7)
  res += ' ' + _id.toString().slice(7, 11)
  res += ' - ' + _id.toString().slice(11, 12)

  return res
}

export const extractExpiration = (token: string): number => {
  if (!token) {
    return 0
  }
  const base64Url = token.split('.')[1]
  if (!base64Url) {
    return 0
  }
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  const tokendata = JSON.parse(window.atob(base64) || '')

  if (tokendata && tokendata.exp) {
    return tokendata.exp
  }
  return 0
}

export const goTo = (path: string) => {
  router.push(path)
}

export const goBack = () => {
  router.back()
}

export const downloadFile = (base64: string, filename: string) => {
  saveAs(base64, filename)
}

export const searchSimulation = (value: string, simulations: Simulation[]) => {
  if (!value || !value.toLowerCase()) return simulations
  return simulations.filter(s => {
    return (
      s.firstname?.toLowerCase().match(value.toLowerCase()) ||
      s.lastname?.toLowerCase().match(value.toLowerCase()) ||
      s.email?.toLowerCase().match(value.toLowerCase()) ||
      s.phone?.toLowerCase().match(value.toLowerCase()) ||
      s.address?.toLowerCase().match(value.toLowerCase()) ||
      s.city?.toLowerCase().match(value.toLowerCase()) ||
      s.cp?.toLowerCase().match(value.toLowerCase()) ||
      t(`Simulation.WorksTypes.${s.name}`)
        .toLowerCase()
        .match(value.toLowerCase()) ||
      toHumanDate(s.date)?.match(value)
    )
  })
}

export const searchUser = (value: string, users: User[]) => {
  if (!value || !value.toLowerCase()) return users
  return users.filter(u => {
    return (
      u.username?.toLowerCase().match(value.toLowerCase()) ||
      u.firstname?.toLowerCase().match(value.toLowerCase()) ||
      u.lastname?.toLowerCase().match(value.toLowerCase()) ||
      u.email?.toLowerCase().match(value.toLowerCase()) ||
      u.phone?.toLowerCase().match(value.toLowerCase())
    )
  })
}

export const toHumanDate = (date: string | undefined): string => {
  if (!date) return ''
  return dayjs(date).format('DD/MM/YYYY')
}

export const checkSiret = (siret: string): boolean => {
  if (!siret) return true
  const trimSiret = siret.replace(/ /g, '')
  if (trimSiret.length === 14) {
    return true
  }
  return false
}

export const formatEuro = (
  n: number | undefined,
  precision: number = 2,
): string => {
  if (n === undefined) return ''
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: precision,
  }).format(n)
}
