export enum TaxBracket {
  VERY_LOW = '1',
  LOW = '2',
  MEDIUM = '3',
  HIGH = '4',
}

export enum WorkType {
  MUR_EXT = '1',
  MUR_INT = '2',
  RAMPANT = '3',
  TERRASSE = '4',
  PAROI = '5',
  COMBLE = '6',
  AUTRE = '7',
}

export enum OwnerType {
  LANDLORD = '1',
  LESSOR = '2',
  TENANT = '3',
  SECOND_HOME = '4',
}

export enum PowerType {
  ELECTRIC = '1',
  COMBUSTIBLE = '2',
}

export const ALL_TAX_BRACKETS = [
  TaxBracket.VERY_LOW,
  TaxBracket.LOW,
  TaxBracket.MEDIUM,
  TaxBracket.HIGH,
]

export const ALL_OWNER_TYPES = [
  OwnerType.LANDLORD,
  OwnerType.LESSOR,
  // OwnerType.TENANT,
  // OwnerType.SECOND_HOME,
]

export const ALL_POWER_TYPES = [PowerType.ELECTRIC, PowerType.COMBUSTIBLE]

export interface Cee {
  id?: number
  projectId?: number
  taxBracket?: TaxBracket
  works?: WorkType
  power?: PowerType
  price?: number
}

export interface Primes {
  [TaxBracket.VERY_LOW]?: number
  [TaxBracket.LOW]?: number
  [TaxBracket.MEDIUM]?: number
  [TaxBracket.HIGH]?: number
}

export interface Simulation {
  id?: number
  firstname?: string
  lastname?: string
  email?: string
  phone?: string
  userId?: number
  date?: string
  address?: string
  cp?: string
  city?: string
  ownerType?: OwnerType
  powerType?: PowerType
  incomes?: number
  person?: number
  noAuto?: boolean
  taxBracket?: TaxBracket
  name?: WorkType
  count?: number
  priceEquipment?: number
  priceEquipmentPerCount?: number
  priceWorkforce?: number
  priceWorkforcePerCount?: number
  ceeName?: string
  otherName?: string
  otherPrime?: number
  emailUser?: boolean
  emailContact?: boolean
  comment?: string
  ht?: number
  tva?: number
  ttc?: number
  cee: Primes
  renov: Primes
  disableRenov?: boolean
  fixRenov?: number
}

export interface UpdateSimulationData {
  name: keyof Simulation
  value: never
}

export interface UpdatePrimesData {
  name: keyof Primes
  value: never
}

export interface Works {
  type: WorkType
  hasRenov: boolean
  limit: number
  tva: number
}

export const PLAFONDS_PROVINCE_1 = [15262, 19565, 29148]
export const PLAFONDS_PROVINCE_2 = [22320, 28614, 42848]
export const PLAFONDS_PROVINCE_3 = [26844, 34411, 51592]
export const PLAFONDS_PROVINCE_4 = [31359, 40201, 60336]
export const PLAFONDS_PROVINCE_5 = [35894, 46015, 69081]
export const PLAFONDS_PROVINCE_MORE = [4526, 5797, 8744]

export const PLAFONDS_PROVINCE = [
  PLAFONDS_PROVINCE_1,
  PLAFONDS_PROVINCE_2,
  PLAFONDS_PROVINCE_3,
  PLAFONDS_PROVINCE_4,
  PLAFONDS_PROVINCE_5,
  PLAFONDS_PROVINCE_MORE,
]

export const PLAFONDS_PARIS_1 = [21123, 25714, 38184]
export const PLAFONDS_PARIS_2 = [31003, 37739, 56130]
export const PLAFONDS_PARIS_3 = [37232, 45326, 67585]
export const PLAFONDS_PARIS_4 = [43472, 52925, 79041]
export const PLAFONDS_PARIS_5 = [49736, 60546, 90496]
export const PLAFONDS_PARIS_MORE = [6253, 7613, 11455]

export const PLAFONDS_PARIS = [
  PLAFONDS_PARIS_1,
  PLAFONDS_PARIS_2,
  PLAFONDS_PARIS_3,
  PLAFONDS_PARIS_4,
  PLAFONDS_PARIS_5,
  PLAFONDS_PARIS_MORE,
]

const AIDES_MUR_EXT = new Map<TaxBracket, number>([
  [TaxBracket.VERY_LOW, 75],
  [TaxBracket.LOW, 60],
  [TaxBracket.MEDIUM, 40],
  [TaxBracket.HIGH, 15],
])
const AIDES_MUR_INT = new Map<TaxBracket, number>([
  [TaxBracket.VERY_LOW, 25],
  [TaxBracket.LOW, 20],
  [TaxBracket.MEDIUM, 15],
  [TaxBracket.HIGH, 7],
])
const AIDES_RAMPANT = new Map<TaxBracket, number>([
  [TaxBracket.VERY_LOW, 25],
  [TaxBracket.LOW, 20],
  [TaxBracket.MEDIUM, 15],
  [TaxBracket.HIGH, 7],
])
const AIDES_TERRASSE = new Map<TaxBracket, number>([
  [TaxBracket.VERY_LOW, 75],
  [TaxBracket.LOW, 60],
  [TaxBracket.MEDIUM, 40],
  [TaxBracket.HIGH, 15],
])
const AIDES_PAROI = new Map<TaxBracket, number>([
  [TaxBracket.VERY_LOW, 100],
  [TaxBracket.LOW, 80],
  [TaxBracket.MEDIUM, 40],
  [TaxBracket.HIGH, 0],
])

export const AIDES_TRAVAUX = new Map<WorkType, Map<TaxBracket, number>>([
  [WorkType.MUR_EXT, AIDES_MUR_EXT],
  [WorkType.MUR_INT, AIDES_MUR_INT],
  [WorkType.RAMPANT, AIDES_RAMPANT],
  [WorkType.TERRASSE, AIDES_TERRASSE],
  [WorkType.PAROI, AIDES_PAROI],
])

export const TVA = 0.055
export const TVA_5 = 0.055

export const ALL_WORKS: Works[] = [
  {
    type: WorkType.COMBLE,
    hasRenov: false,
    limit: Number.MAX_SAFE_INTEGER,
    tva: TVA_5,
  },
  {
    type: WorkType.MUR_EXT,
    hasRenov: true,
    limit: 100,
    tva: TVA_5,
  },
  {
    type: WorkType.MUR_INT,
    hasRenov: true,
    limit: Number.MAX_SAFE_INTEGER,
    tva: TVA_5,
  },
  {
    type: WorkType.RAMPANT,
    hasRenov: true,
    limit: Number.MAX_SAFE_INTEGER,
    tva: TVA_5,
  },
  {
    type: WorkType.TERRASSE,
    hasRenov: true,
    limit: Number.MAX_SAFE_INTEGER,
    tva: TVA_5,
  },
  {
    type: WorkType.PAROI,
    hasRenov: true,
    limit: Number.MAX_SAFE_INTEGER,
    tva: TVA_5,
  },
  {
    type: WorkType.AUTRE,
    hasRenov: true,
    limit: Number.MAX_SAFE_INTEGER,
    tva: TVA_5,
  },
]
