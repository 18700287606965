
import { toHumanDate, formatEuro, goTo } from '@/common/util'
import { t, tc } from '@/locales/index'
import { PropType, ref, SetupContext, watch } from 'vue'
import { Simulation, TaxBracket, ALL_TAX_BRACKETS } from '@/model/Simulation'
import { Toaster } from '@/common/Toaster'
import { Inputs } from '@/common/Inputs'
import { StoreActionsSimulation, storeSimulation } from '@/store/simulation'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import TablePrimes from './TablePrimes.vue'

export default {
  components: { TablePrimes },
  props: {
    simulation: {
      type: Object as PropType<Simulation>,
      required: true,
    },
  },
  emits: ['update'],
  setup(
    props: Readonly<{
      simulation: Simulation
    }>,
    context: SetupContext,
  ) {
    const loading = ref(false)
    const simulationValid = ref(false)

    watch(
      () => props.simulation,
      () => {
        setTimeout(() => {
          simulationValid.value =
            Inputs.getInputsValidity('.form-simulation-contact') &&
            Inputs.getInputsValidity('.form-simulation-works')
        })
      },
      { immediate: true, deep: true },
    )

    const validate = async () => {
      loading.value = true
      const simulation = props.simulation
      simulation.userId = storeAuth.getters[StoreGettersAuth.LOGGED_USER].id

      const response = await storeSimulation.dispatch(
        StoreActionsSimulation.ADD,
        props.simulation,
      )
      loading.value = false
      Toaster.toast(response)
      if (response.success) {
        goTo(`/simulations/${response.data.id}`)
      }
    }

    const checkValidity = () => {
      Inputs.checkInputsValidity('.form-simulation-contact')
      Inputs.checkInputsValidity('.form-simulation-works')
    }

    const updateField = (name: string, value: string | number | boolean) => {
      context.emit('update', { name, value })
    }

    return {
      t,
      tc,
      toHumanDate,
      formatEuro,
      checkValidity,
      updateField,
      validate,
      loading,
      simulationValid,
      TaxBracket,
      ALL_TAX_BRACKETS,
    }
  },
}
