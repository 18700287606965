import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { User } from '@/model/User'
import { InjectionKey } from 'vue'
import { ActionTree, createStore, GetterTree, MutationTree, Store } from 'vuex'

export interface StoreStateUser {
  user: User
  users: User[]
}

export enum StoreGettersUser {
  USER = 'USER',
  HAS_USER = 'HAS_USER',
  USERS = 'USERS',
}

export enum StoreActionsUser {
  GET_BY_ID = 'GET_BY_ID',
  GET_ALL = 'GET_ALL',
  SEARCH = 'SEARCH',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  REGENERATE_ACTIVATION = 'REGENERATE_ACTIVATION',
}

export enum StoreMutationsUser {
  SET_USER = 'SET_USER',
  SET_USERS = 'SET_USERS',
  PUSH_USER = 'PUSH_USER',
  SHIFT_USER = 'SHIFT_USER',
  UPDATE_USER = 'UPDATE_USER',
}

const state: StoreStateUser = {
  user: {},
  users: [],
}

const getters: GetterTree<StoreStateUser, StoreStateUser> = {
  [StoreGettersUser.USER](state): User {
    return state.user
  },
  [StoreGettersUser.USERS](state): User[] {
    return state.users
  },
  [StoreGettersUser.HAS_USER](state): boolean {
    return !!(state.user && state.user?.id)
  },
}

const actions: ActionTree<StoreStateUser, StoreStateUser> = {
  async [StoreActionsUser.GET_BY_ID]({ commit }, id): Promise<ServerResponse> {
    const response = await apiHandler.get(`users/${id}`)
    commit(StoreMutationsUser.SET_USER, response.data)
    return response
  },
  async [StoreActionsUser.GET_ALL]({ commit }): Promise<ServerResponse> {
    const response = await apiHandler.get(`users/`)
    commit(StoreMutationsUser.SET_USERS, response.data)
    return response
  },
  async [StoreActionsUser.SEARCH](
    { commit },
    searchdata,
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(`users/search`, searchdata)
    commit(StoreMutationsUser.SET_USERS, response.data)
    return response
  },
  async [StoreActionsUser.ADD](
    { commit },
    user: User,
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(`users/register`, user)
    if (response.success) {
      commit(StoreMutationsUser.PUSH_USER, response.data)
    }
    return response
  },
  async [StoreActionsUser.UPDATE](
    { commit },
    { id, user },
  ): Promise<ServerResponse> {
    const response = await apiHandler.put(`users/${id}`, user)
    if (response.success) {
      commit(StoreMutationsUser.UPDATE_USER, response.data)
    }
    return response
  },
  async [StoreActionsUser.DELETE]({ commit }, id): Promise<ServerResponse> {
    const response = await apiHandler.delete(`users/${id}`)
    if (response.success) {
      commit(StoreMutationsUser.SHIFT_USER, id)
    }
    return response
  },
  async [StoreActionsUser.REGENERATE_ACTIVATION](
    { commit },
    userId,
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(`users/${userId}/re-activate`)
    if (response.success) {
      commit(StoreMutationsUser.UPDATE_USER, response.data)
    }
    return response
  },
}

const mutations: MutationTree<StoreStateUser> = {
  [StoreMutationsUser.SET_USERS](state, users: User[]): void {
    state.users = users
  },
  [StoreMutationsUser.SET_USER](state, user: User): void {
    state.user = user
  },
  [StoreMutationsUser.PUSH_USER](state, user: User): void {
    state.users.push(user)
  },
  [StoreMutationsUser.SHIFT_USER](state, id: number): void {
    state.users = state.users.filter(p => p.id !== id)
  },
  [StoreMutationsUser.UPDATE_USER](state, user: User): void {
    state.user = user
    state.users = state.users.map(p => (p.id === user.id ? user : p))
  },
}

export const key: InjectionKey<Store<StoreStateUser>> = Symbol(undefined)

export const storeUser = createStore<StoreStateUser>({
  state,
  mutations,
  getters,
  actions,
})
