
import { PropType, Ref, ref, SetupContext, watch } from 'vue'
import { t } from '@/locales/index'
import {
  OwnerType,
  PowerType,
  Simulation,
  TaxBracket,
  ALL_TAX_BRACKETS,
  ALL_OWNER_TYPES,
  ALL_POWER_TYPES,
} from '@/model/Simulation'
import TableTax from '@/components/core/simulation/TableTax.vue'

export default {
  components: {
    TableTax,
  },
  props: {
    simulation: Object as PropType<Simulation>,
    loading: Boolean,
  },
  emits: ['update', 'reset'],
  setup(
    props: Readonly<
      {
        loading: boolean
      } & {
        simulation?: Simulation
      }
    >,
    context: SetupContext,
  ) {
    const simulationForm: Ref<Simulation> = ref({ cee: {}, renov: {} })

    watch(
      () => props.simulation,
      () => {
        simulationForm.value = JSON.parse(
          JSON.stringify(props.simulation || {}),
        )
      },
      { immediate: true, deep: true },
    )

    const updateField = (name: string, value: string | number | boolean) => {
      context.emit('update', { name, value })
    }

    const reset = () => {
      context.emit('reset')
    }

    return {
      t,
      updateField,
      simulationForm,
      OwnerType,
      TaxBracket,
      reset,
      PowerType,
      ALL_TAX_BRACKETS,
      ALL_OWNER_TYPES,
      ALL_POWER_TYPES,
    }
  },
}
