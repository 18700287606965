import { DigInputController } from '@digithia/input'
export class Inputs {
  static checkInputsValidity(selector: string): boolean {
    return DigInputController.checkInputsValidity(selector)
  }

  static resetInputsValidity(selector: string): void {
    return DigInputController.resetInputsValidity(selector)
  }

  static getInputsValidity(selector: string): boolean {
    return DigInputController.getInputsValidity(selector)
  }

  static checkPassword(password: string): boolean {
    if (!password) return true
    return password.length >= 8
  }
}
