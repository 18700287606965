import {
  PLAFONDS_PROVINCE,
  PLAFONDS_PARIS,
  TaxBracket,
  AIDES_TRAVAUX,
  Simulation,
  Cee,
  Primes,
  ALL_TAX_BRACKETS,
  ALL_WORKS,
} from '@/model/Simulation'

export const getPlafonds = (
  person: number,
  province: boolean = false,
): number[] => {
  let maxIncomes: number[]
  let plafonds: number[][]
  if (province) {
    plafonds = PLAFONDS_PROVINCE
  } else {
    plafonds = PLAFONDS_PARIS
  }

  if (person > 5) {
    maxIncomes = plafonds[4].map((incomes, i) => {
      return incomes + plafonds[5][i] * (person - 5)
    })
  } else {
    maxIncomes = plafonds[person - 1]
  }
  return maxIncomes
}

export const isParis = (cp: string): boolean => {
  return !!cp?.match(/^(75|77|78|91|92|93|94|95)/)
}

export const getTaxBracket = (
  incomes: number | undefined,
  person: number | undefined,
  cp: string,
): TaxBracket | undefined => {
  if (!incomes || !person) return undefined
  const province = !isParis(cp)
  const plafonds = getPlafonds(person, province)

  if (incomes > plafonds[2]) {
    return TaxBracket.HIGH
  }
  if (incomes > plafonds[1]) {
    return TaxBracket.MEDIUM
  }
  if (incomes > plafonds[0]) {
    return TaxBracket.LOW
  }
  return TaxBracket.VERY_LOW
}

export const getCeePrimes = (simulation: Simulation, cees: Cee[]): Primes => {
  const cee: Primes = {}
  for (const taxBracket of ALL_TAX_BRACKETS) {
    cee[taxBracket] = getPrimeCee(simulation, taxBracket, cees)
  }
  return cee
}

export const getRenovPrimes = (simulation: Simulation): Primes => {
  const renov: Primes = {}
  for (const taxBracket of ALL_TAX_BRACKETS) {
    renov[taxBracket] = getPrimeRenov(simulation, taxBracket)
  }
  return renov
}

export const clipPrimes = (simulation: Simulation): Simulation => {
  const ttc = simulation.ttc || 0
  if (!simulation.cee) simulation.cee = {}
  if (!simulation.renov) simulation.renov = {}

  const work = ALL_WORKS.find(w => w.type === simulation.name)
  for (const taxBracket of ALL_TAX_BRACKETS) {
    const maxRatio = work?.hasRenov ? getMaxRatio(taxBracket) : 1

    const other = Math.min(simulation.otherPrime || 0, maxRatio * ttc)
    let cee = Math.min(simulation.cee[taxBracket] || 0, maxRatio * ttc - other)
    if (!work?.hasRenov && other + cee >= ttc) {
      cee -= 1
    }
    const renov = Math.min(
      simulation.renov[taxBracket] || 0,
      maxRatio * ttc - other - cee,
    )

    if (simulation.otherPrime === undefined || simulation.otherPrime === null) {
      simulation.otherPrime = undefined
    } else {
      simulation.otherPrime = +other.toFixed(2)
    }

    if (
      simulation.cee[taxBracket] === undefined ||
      simulation.cee[taxBracket] === null
    ) {
      simulation.cee[taxBracket] = undefined
    } else {
      simulation.cee[taxBracket] = +cee.toFixed(2)
    }
    simulation.renov[taxBracket] = +renov.toFixed(2)
  }
  return simulation
}

export const getPrimeRenov = (
  simulation: Simulation,
  taxBracket: TaxBracket,
): number => {
  if (!simulation || simulation.name === undefined || simulation.disableRenov) {
    return 0
  }

  if (simulation.fixRenov) {
    return simulation.fixRenov
  }

  const work = ALL_WORKS.find(w => w.type === simulation.name)
  if (!work?.hasRenov) return 0

  const renovPrime = Math.max(
    0,
    (AIDES_TRAVAUX.get(simulation.name)?.get(taxBracket) || 0) *
      Math.min(simulation.count || 0, work?.limit || Number.MAX_SAFE_INTEGER),
  )

  return renovPrime
}

export const getPrimeCee = (
  simulation: Simulation,
  taxBracket: TaxBracket,
  ceePrimes: Cee[],
): number => {
  const filteredPrimes = ceePrimes.filter((cee: Cee) => {
    return cee.works === simulation.name && cee.power === simulation.powerType
  })
  if (!filteredPrimes || !filteredPrimes.length) return 0

  const count = simulation.count || 0
  const res =
    (filteredPrimes.find(p => p.taxBracket === taxBracket)?.price || 0) * count

  return +res.toFixed(2)
}

export const getMaxRatio = (taxBracket: TaxBracket | undefined): number => {
  if (taxBracket === TaxBracket.VERY_LOW) {
    return 0.9
  } else if (taxBracket === TaxBracket.LOW) {
    return 0.75
  } else if (taxBracket === TaxBracket.MEDIUM) {
    return 0.6
  } else if (taxBracket === TaxBracket.HIGH) {
    return 0.4
  }
  return 0
}
