<template>
  <dig-ui-toaster bottom></dig-ui-toaster>
  <base-update></base-update>
  <router-view />
</template>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  --simulateur-color-card: #ccc;
  --simulateur-color-primary: #2681a2;
  --simulateur-color-secondary: #11464f;
  --simulateur-color-cancel: #555;

  --simulateur-color-tax-bracket: rgb(208, 208, 208);
  --simulateur-color-tax-bracket-very-low: rgb(172, 178, 216);
  --simulateur-color-tax-bracket-low: rgb(245, 235, 189);
  --simulateur-color-tax-bracket-medium: rgb(177, 132, 145);
  --simulateur-color-tax-bracket-high: rgb(241, 202, 182);
}

.pdf-viewer {
  width: 100%;
  min-height: 500px;
}

dig-ui-title {
  --dig-ui-title-color: var(--simulateur-color-primary);
  --dig-ui-title-padding-vertical: 0px;
  --dig-ui-title-padding-horizontal: 0px;

  &.center {
    --dig-ui-title-text-align: center;
  }

  &.title-1 {
    --dig-ui-title-font-size: 28px;
  }

  &.title-2 {
    --dig-ui-title-font-size: 24px;
  }

  &.title-3 {
    --dig-ui-title-font-size: 20px;
    --dig-ui-title-color: var(--simulateur-color-secondary);
  }
}

dig-ui-loader {
  --dig-ui-loader-border-color: var(--simulateur-color-primary);
}

dig-ui-standard-button {
  --dig-ui-standard-button-background-color: var(--simulateur-color-primary);

  &.cancel {
    --dig-ui-standard-button-background-color: var(--simulateur-color-cancel);
  }
}

.loader-container {
  padding: 20px;
  text-align: center;
}

.buttons-container {
  padding: 0px;
  text-align: center;

  dig-ui-standard-button {
    margin: 10px;
  }
}

.pagination-container {
  margin: 20px 0px;
  text-align: center;
}

.ctrl-buttons {
  margin: 4px;
  padding: 0;
  display: inline-flex;
  float: right;

  .icon-button {
    margin-left: 12px;
  }
}

.no-data-container {
  text-align: center;
  padding: 12px 6px;
}

.item-name {
  color: #777;
}
.item-value {
  font-weight: bold;
}
.item-number-value {
}
</style>
