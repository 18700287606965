
import { PropType, ref, SetupContext } from 'vue'
import { t } from '@/locales/index'
import { Simulation, UpdateSimulationData } from '@/model/Simulation'
import SimulationWorksForm from '@/components/core/simulation/SimulationWorksForm.vue'

export default {
  components: {
    SimulationWorksForm,
  },
  props: {
    simulation: Object as PropType<Simulation>,
    loading: Boolean,
  },
  emits: ['update', 'updateCee', 'reset'],
  setup(
    props: Readonly<
      {
        loading: boolean
      } & {
        simulation?: Simulation | undefined
      }
    >,
    context: SetupContext,
  ) {
    const editing = ref(true)
    const loadingSimulationWorks = ref(false)
    const loadingForm = ref(false)

    const updateField = (event: UpdateSimulationData) => {
      context.emit('update', event)
    }

    const updateCee = (event: UpdateSimulationData) => {
      context.emit('updateCee', event)
    }

    return {
      editing,
      updateField,
      loadingSimulationWorks,
      loadingForm,
      updateCee,
      t,
    }
  },
}
