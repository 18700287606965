
import { onMounted, Ref, ref } from 'vue'

export default {
  setup() {
    const registration: Ref<ServiceWorkerRegistration | null> = ref(null)
    const updateExists = ref(false)
    const refreshing = ref(false)

    onMounted(() => {
      console.log('add event sw updated')
      document.addEventListener('swUpdated', updateAvailable, { once: true })

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing.value) return
        refreshing.value = true
        window.location.reload()
      })
    })

    const updateAvailable = (event: Event) => {
      console.log('new update sw !')
      registration.value = (event as CustomEvent).detail
      updateExists.value = true
    }

    const refreshApp = () => {
      updateExists.value = false
      localStorage.setItem('simulation', '{}')
      if (!registration.value || !registration.value?.waiting) return
      registration.value?.waiting.postMessage({ type: 'SKIP_WAITING' })
    }

    const close = () => {
      updateExists.value = false
    }

    return {
      registration,
      updateExists,
      updateAvailable,
      refreshApp,
      close,
    }
  },
}
