import { InjectionKey } from 'vue'
import { ActionTree, createStore, GetterTree, MutationTree, Store } from 'vuex'

export interface NavState {
  toggled: boolean
  mobile: boolean
}

export interface NavStoreState {
  navState: NavState
}

export enum NavStoreGetters {
  NAV_STATE = 'IS_SIDEBAR_TOGGLED',
}

export enum NavStoreActions {
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
}

export enum NavStoreMutations {
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
}

const state: NavStoreState = {
  navState: { toggled: false, mobile: window.innerWidth <= 600 },
}

const getters: GetterTree<NavStoreState, NavStoreState> = {
  [NavStoreGetters.NAV_STATE](): NavState {
    state.navState.mobile = window.innerWidth <= 600
    return state.navState
  },
}

const actions: ActionTree<NavStoreState, NavStoreState> = {
  [NavStoreActions.TOGGLE_SIDEBAR]({ commit }, toggled: boolean): void {
    commit(NavStoreMutations.TOGGLE_SIDEBAR, toggled)
  },
}

const mutations: MutationTree<NavStoreState> = {
  [NavStoreMutations.TOGGLE_SIDEBAR](state, toggled?: boolean): void {
    if (toggled === undefined) {
      state.navState.toggled = !state.navState.toggled
    } else {
      state.navState.toggled = toggled
    }
  },
}

export const key: InjectionKey<Store<NavStoreState>> = Symbol(undefined)

export const storeNav = createStore<NavStoreState>({
  state,
  mutations,
  getters,
  actions,
})
