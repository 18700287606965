
import { onMounted, ref } from 'vue'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import {
  StoreActionsSimulation,
  storeSimulation,
  StoreGettersSimulation,
} from '@/store/simulation'
import SimulationDisplay from '@/components/core/simulation/SimulationDisplay.vue'
import { useRoute } from 'vue-router'
import { t } from '@/locales'
import { downloadFile, goTo } from '@/common/util'
import { Toaster } from '@/common/Toaster'
import { UpdateSimulationData } from '@/model/Simulation'

export default {
  components: {
    SimulationDisplay,
  },
  setup() {
    const loading = ref(true)
    const route = useRoute()

    onMounted(
      async (): Promise<void> => {
        loading.value = true
        await storeSimulation.dispatch(
          StoreActionsSimulation.GET_BY_ID,
          route.params.id,
        )
        await storeSimulation.dispatch(
          StoreActionsSimulation.GET_REPORT,
          route.params.id,
        )
        loading.value = false
      },
    )

    const deleteSimulation = async (id: number): Promise<void> => {
      if (confirm(t('askDeleteSimulation'))) {
        const response = await storeSimulation.dispatch(
          StoreActionsSimulation.DELETE,
          id,
        )
        Toaster.toast(response)
      }
    }

    const regenerate = async (): Promise<void> => {
      loading.value = true
      const response = await storeSimulation.dispatch(
        StoreActionsSimulation.RE_GENERATE,
        route.params.id,
      )
      loading.value = false
      Toaster.toast(response)
    }

    const sendToEmail = async (): Promise<void> => {
      loading.value = true
      const response = await storeSimulation.dispatch(
        StoreActionsSimulation.RE_SEND,
        route.params.id,
      )
      loading.value = false
      Toaster.toast(response)
    }

    const update = (event: UpdateSimulationData): void => {
      storeSimulation.dispatch(StoreActionsSimulation.UPDATE, {
        id: route.params.id,
        simulation: {
          ...storeSimulation.getters[StoreGettersSimulation.SIMULATION],
          [event.name]: event.value,
        },
      })
    }

    const download = (): void => {
      if (storeSimulation.getters[StoreGettersSimulation.REPORT]) {
        downloadFile(
          storeSimulation.getters[StoreGettersSimulation.REPORT],
          'rapport.pdf',
        )
        Toaster.toast({
          message: 'Téléchargement du PDF commencé',
          type: 'success',
        })
      } else {
        Toaster.toast({
          message: 'Aucun rapport à télécharger',
          type: 'warning',
        })
      }
    }

    return {
      goTo,
      loading,
      storeAuth,
      StoreGettersAuth,
      StoreGettersSimulation,
      storeSimulation,
      deleteSimulation,
      regenerate,
      sendToEmail,
      update,
      download,
      t,
    }
  },
}
