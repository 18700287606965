<template>
  <dig-ui-view
    :toggled="navState.toggled"
    @toggle="toggle($event.detail)"
    :style="appStyle"
  >
    <dig-ui-nav-bar-item
      slot="nav-bar"
      @click="toggle()"
      :title="t('Tooltips.closeMenu')"
    >
      <dig-ui-menu-button :toggled="navState.toggled"></dig-ui-menu-button>
    </dig-ui-nav-bar-item>
    <dig-ui-nav-bar-item
      slot="nav-bar"
      @click="goTo('/home')"
      class="image"
      :title="t('Tooltips.home')"
    >
      <img
        height="30"
        :src="storeProject.getters[StoreGettersProject.LOGO]"
        :alt="t('Images.logoNav')"
      />
    </dig-ui-nav-bar-item>
    <dig-ui-nav-bar-item
      slot="nav-bar"
      @click="goTo('/account')"
      :title="t('Tooltips.myAccount')"
    >
      {{ t('Menu.account') }}
    </dig-ui-nav-bar-item>
    <dig-ui-nav-bar-item
      slot="nav-bar"
      @click="logout()"
      :title="t('Tooltips.logout')"
    >
      {{ t('Menu.logout') }}
    </dig-ui-nav-bar-item>
    <dig-ui-nav-bar-item
      v-if="offline"
      slot="nav-bar"
      :title="t('Tooltips.offline')"
    >
      {{ t('Menu.offline') }}
    </dig-ui-nav-bar-item>

    <nav slot="side-bar">
      <dig-ui-side-bar-item @click="goBack()" :title="t('Tooltips.goBack')">
        {{ t('Menu.back') }}
      </dig-ui-side-bar-item>
      <dig-ui-side-bar-item @click="goTo('/home')" :title="t('Tooltips.home')">
        {{ t('Menu.home') }}
      </dig-ui-side-bar-item>
      <dig-ui-side-bar-item
        @click="goTo('/new-simulation')"
        :title="t('Tooltips.newSimulation')"
      >
        {{ t('Menu.newSimulation') }}
      </dig-ui-side-bar-item>
      <dig-ui-side-bar-item
        @click="goTo('/dossiers')"
        :title="t('Tooltips.simulations')"
      >
        {{ t('Menu.simulations') }}
      </dig-ui-side-bar-item>
      <dig-ui-side-bar-item
        v-if="isAdmin"
        @click="goTo('/users')"
        :title="t('Tooltips.users')"
      >
        {{ t('Menu.users') }}
      </dig-ui-side-bar-item>
      <dig-ui-side-bar-item
        v-if="isAdmin"
        @click="goTo('/project')"
        :title="t('Tooltips.project')"
      >
        {{ t('Menu.project') }}
      </dig-ui-side-bar-item>
      <dig-ui-side-bar-item
        v-if="isAdmin"
        @click="goTo('/project/usage')"
        :title="t('Tooltips.usage')"
      >
        {{ t('Menu.usage') }}
      </dig-ui-side-bar-item>
      <dig-ui-side-bar-item
        v-if="isAdmin"
        @click="goTo('/project/cee')"
        :title="t('Tooltips.cee')"
      >
        {{ t('Menu.cee') }}
      </dig-ui-side-bar-item>
    </nav>

    <div slot="content" class="content">
      <router-view></router-view>
    </div>

    <div slot="footer" class="footer">
      <div class="footer-1">
        <dig-ui-nav-bar-item @click="goBack()">
          {{ t('Menu.back') }}
        </dig-ui-nav-bar-item>
        <dig-ui-nav-bar-item @click="goTo('/home')">
          {{ t('Menu.home') }}
        </dig-ui-nav-bar-item>
        <dig-ui-nav-bar-item @click="goTo('/simulation')">
          {{ t('Menu.newSimulation') }}
        </dig-ui-nav-bar-item>
        <dig-ui-nav-bar-item @click="goTo('/dossiers')">
          {{ t('Menu.simulations') }}
        </dig-ui-nav-bar-item>
        <dig-ui-nav-bar-item v-if="isAdmin" @click="goTo('/users')">
          {{ t('Menu.users') }}
        </dig-ui-nav-bar-item>
        <dig-ui-nav-bar-item v-if="isAdmin" @click="goTo('/project')">
          {{ t('Menu.project') }}
        </dig-ui-nav-bar-item>
        <dig-ui-nav-bar-item v-if="isAdmin" @click="goTo('/project/usage')">
          {{ t('Menu.usage') }}
        </dig-ui-nav-bar-item>
        <dig-ui-nav-bar-item v-if="isAdmin" @click="goTo('/project/cee')">
          {{ t('Menu.cee') }}
        </dig-ui-nav-bar-item>
      </div>
      <div class="footer-2">
        <p>
          {{ t('loggedAs', { user: loggedUser.username }) }}
        </p>
        <p>
          {{ t('currentRole', { role: t(`Role.${loggedUser.role}`) }) }}
        </p>
      </div>
      <div class="footer-3">
        <p>
          {{ t('currentVersion', { version: '1.0.1' }) }}
        </p>
      </div>
    </div>
  </dig-ui-view>
</template>

<script>
import { ref, onMounted } from 'vue'
import { goBack, goTo } from '@/common/util'
import { storeAuth, StoreActionsAuth, StoreGettersAuth } from '@/store/auth'
import { storeNav, NavStoreActions, NavStoreGetters } from '@/store/nav'
import {
  storeProject,
  StoreActionsProject,
  StoreGettersProject,
} from '@/store/project'
import { storeCee, StoreActionsCee } from '@/store/cee'
import { Role } from '@/model/User'
import { t } from '@/locales'

export default {
  setup() {
    const isAdmin = ref(storeAuth.getters[StoreGettersAuth.IS_ADMIN])
    const loggedUser = ref(storeAuth.getters[StoreGettersAuth.LOGGED_USER])
    const navState = ref(storeNav.getters[NavStoreGetters.NAV_STATE])
    const appStyle = ref({})
    const offline = ref(false)

    onMounted(async () => {
      await storeProject.dispatch(
        StoreActionsProject.GET_BY_ID,
        storeAuth.getters[StoreGettersAuth.LOGGED_USER].projectId,
      )
      await storeProject.dispatch(
        StoreActionsProject.GET_LOGO,
        storeAuth.getters[StoreGettersAuth.LOGGED_USER].projectId,
      )
      await storeCee.dispatch(StoreActionsCee.SEARCH, {
        projectId: storeAuth.getters[StoreGettersAuth.LOGGED_USER].projectId,
      })

      appStyle.value = {
        '--simulateur-color-primary':
          storeProject.getters[StoreGettersProject.PROJECT].color,
      }

      document.addEventListener('swOffline', event => {
        offline.value = event.detail
      })
    })

    const toggle = toggled => {
      storeNav.dispatch(NavStoreActions.TOGGLE_SIDEBAR, toggled)
    }

    const logout = async () => {
      await storeAuth.dispatch(StoreActionsAuth.LOGOUT)
      goTo('/login')
    }

    return {
      goTo,
      goBack,
      logout,
      isAdmin,
      Role,
      storeNav,
      NavStoreActions,
      NavStoreGetters,
      toggle,
      navState,
      loggedUser,
      t,
      appStyle,
      storeProject,
      StoreGettersProject,
      offline,
    }
  },
  created() {
    document.title = 'Simulateur'
  },
}
</script>

<style scoped lang="scss">
.content {
  margin: 0;
  padding: 4px 10px;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;

  .footer-1,
  .footer-2,
  .footer-3 {
    padding: 10px 20px;
  }

  .footer-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .image {
    img {
      height: 20px;
    }
  }

  dig-ui-nav-bar-item {
    --dig-ui-nav-bar-item-padding: 4px 6px;
    --dig-ui-nav-bar-item-font-size: 12px;
  }

  dig-ui-menu-button {
    --dig-ui-menu-button-icon-size: 20px;
  }

  .footer {
    font-size: 12px;

    .footer-1,
    .footer-2,
    .footer-3 {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 400px) {
  .footer {
    grid-template-columns: 1fr;
  }
}
</style>
