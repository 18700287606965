import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { Project } from '@/model/Project'
import { Usage } from '@/model/Usage'
import { InjectionKey } from 'vue'
import { ActionTree, createStore, GetterTree, MutationTree, Store } from 'vuex'

export interface StoreStateProject {
  project: Project
  projects: Project[]
  logo: string
  usages: Usage[]
}

export enum StoreGettersProject {
  PROJECT = 'PROJECT',
  PROJECTS = 'PROJECTS',
  LOGO = 'LOGO',
  USAGES = 'USAGES',
}

export enum StoreActionsProject {
  GET_BY_ID = 'GET_BY_ID',
  GET_ALL = 'GET_ALL',
  SEARCH = 'SEARCH',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  GET_LOGO = 'GET_LOGO',
  GET_USAGES = 'GET_USAGES',
}

export enum StoreMutationsProject {
  SET_PROJECT = 'SET_PROJECT',
  SET_PROJECTS = 'SET_PROJECTS',
  PUSH_PROJECT = 'PUSH_PROJECT',
  SHIFT_PROJECT = 'SHIFT_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  SET_LOGO = 'SET_LOGO',
  SET_USAGES = 'SET_USAGES',
}

const state: StoreStateProject = {
  project: {},
  projects: [],
  logo: '',
  usages: [],
}

const getters: GetterTree<StoreStateProject, StoreStateProject> = {
  [StoreGettersProject.PROJECT](state): Project {
    return state.project
  },
  [StoreGettersProject.PROJECTS](state): Project[] {
    return state.projects
  },
  [StoreGettersProject.LOGO](state): string {
    return state.logo
  },
  [StoreGettersProject.USAGES](state): Usage[] {
    return state.usages
  },
}

const actions: ActionTree<StoreStateProject, StoreStateProject> = {
  async [StoreActionsProject.GET_BY_ID](
    { commit },
    id,
  ): Promise<ServerResponse> {
    const response = await apiHandler.get(`projects/${id}`)
    commit(StoreMutationsProject.SET_PROJECT, response.data)
    return response
  },
  async [StoreActionsProject.GET_ALL]({ commit }): Promise<ServerResponse> {
    const response = await apiHandler.get(`projects/`)
    commit(StoreMutationsProject.SET_PROJECTS, response.data)
    return response
  },
  async [StoreActionsProject.SEARCH](
    { commit },
    searchdata,
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(`projects/search`, searchdata)
    commit(StoreMutationsProject.SET_PROJECTS, response.data)
    return response
  },
  async [StoreActionsProject.ADD](
    { commit },
    project: Project,
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(`projects`, project)
    if (response.success) {
      commit(StoreMutationsProject.PUSH_PROJECT, response.data)
    }
    return response
  },
  async [StoreActionsProject.UPDATE](
    { commit },
    { id, project },
  ): Promise<ServerResponse> {
    const response = await apiHandler.put(`projects/${id}`, project)
    if (response.success) {
      commit(StoreMutationsProject.UPDATE_PROJECT, response.data)
    }
    return response
  },
  async [StoreActionsProject.DELETE]({ commit }, id): Promise<ServerResponse> {
    const response = await apiHandler.delete(`projects/${id}`)
    if (response.success) {
      commit(StoreMutationsProject.SHIFT_PROJECT, id)
    }
    return response
  },
  async [StoreActionsProject.GET_LOGO](
    { commit },
    id,
  ): Promise<ServerResponse> {
    const response = await apiHandler.get(`projects/${id}/image`)
    if (response.success) {
      commit(StoreMutationsProject.SET_LOGO, response.data)
    }
    return response
  },
  async [StoreActionsProject.GET_USAGES](
    { commit },
    id,
  ): Promise<ServerResponse> {
    const response = await apiHandler.get(`projects/${id}/usages`)
    if (response.success) {
      commit(StoreMutationsProject.SET_USAGES, response.data)
    }
    return response
  },
}

const mutations: MutationTree<StoreStateProject> = {
  [StoreMutationsProject.SET_PROJECTS](state, projects: Project[] = []): void {
    state.projects = projects
  },
  [StoreMutationsProject.SET_PROJECT](state, project: Project = {}): void {
    state.project = project
  },
  [StoreMutationsProject.PUSH_PROJECT](state, project: Project): void {
    state.projects.push(project)
  },
  [StoreMutationsProject.SHIFT_PROJECT](state, id: number): void {
    state.projects = state.projects.filter(p => p.id !== id)
  },
  [StoreMutationsProject.UPDATE_PROJECT](state, project: Project): void {
    state.project = project
    state.projects = state.projects.map(p =>
      p.id === project.id ? project : p,
    )
  },
  [StoreMutationsProject.SET_LOGO](state, logo: string): void {
    state.logo = logo
  },
  [StoreMutationsProject.SET_USAGES](state, usages: Usage[]): void {
    state.usages = usages
  },
}

export const key: InjectionKey<Store<StoreStateProject>> = Symbol(undefined)

export const storeProject = createStore<StoreStateProject>({
  state,
  mutations,
  getters,
  actions,
})
