import { LocaleMessages, VueMessageType } from 'vue-i18n'
import { Role } from '@/model/User'
import { OwnerType, PowerType, TaxBracket, WorkType } from '@/model/Simulation'

const messages: LocaleMessages<VueMessageType> = {
  fr: {
    hello: `Bonjour`,
    user: `Utilisateur`,
    users: `Utilisateurs`,
    nbUser: `Aucun utilisateur | {n} utilisateur | {n} utilisateurs`,
    project: `Entreprise`,
    projects: `Entreprises`,
    nbProject: `Aucun projet | {n} projet | {n} projets`,
    login: `Se connecter`,
    loginTitle: `Simulateur d'Aides`,
    loginSubtitle: `à la rénovation énergétique`,
    usernameOrEmail: `Nom d'utilisateur ou email`,
    password: `Mot de passe`,
    connection: `Connexion`,
    forgotMyPassword: `Mot de passe oublié ?`,
    home: `Accueil`,
    welcome: `Bienvenue {name}`,
    myAccount: `Mon compte`,
    logout: `Déconnexion`,
    files: `Fichiers`,
    add: `Ajouter`,
    new: `Nouveau`,
    addUser: `Ajouter un utilisateur`,
    filterUser: `Rechercher les utilisateurs`,
    filterUserHint: `Rechercher par nom d'utilisateur, email, nom ou prénom.`,
    filterSimulation: `Rechercher les dossiers`,
    filterSimulationHint: `Rechercher par nom, prénom, date, travaux etc...`,
    pageNotFound: `Page introuvable`,
    noDataAccount: `Impossible d'afficher les données de votre compte`,
    noDataProject: `Impossible d'afficher les données de l'entreprise`,
    noDataUser: `Impossible d'afficher les données de l'utilisateur`,
    name: `Nom`,
    description: `Description`,
    price: `Prix`,
    date: `Date`,
    expand: `Ouvrir`,
    collapse: `Fermer`,
    noData: `Données indisponibles`,
    delete: `Supprimer`,
    activated: `Activé`,
    desactivated: `Désactivé`,
    edit: `Modifier`,
    username: `Nom d'utilisateur`,
    email: `Email`,
    role: `Role`,
    regeneratePDF: `Re-générer le PDF`,
    sendToEmail: `Envoyer le rapport par email`,
    Project: {
      name: `Raison sociale`,
      address: `Adresse`,
      cp: `Code postal`,
      city: `Ville`,
      siret: `SIRET`,
      email: `Email`,
      phone: `Téléphone`,
      color: `Couleur`,
    },
    User: {
      username: `Nom d'utilisateur`,
      email: `Email`,
      firstname: `Prénom`,
      lastname: `Nom`,
      phone: `Téléphone`,
    },
    Role: {
      [Role.superadmin]: `Super administrateur`,
      [Role.admin]: `Administrateur`,
      [Role.user]: `Utilisateur`,
    },
    dontWantToSayIncomes: `La personne ne connait pas ses revenus exacts`,
    primesCee: `Primes CEE`,
    primesOther: `Autre(s) prime(s)`,
    detail: `Détail`,
    download: `Télécharger`,
    maPrimeRenov: `Ma prime renov'`,
    disableMaPrimeRenov: `Désactiver le calcul de Ma prime Renov'`,
    Simulation: {
      undefined: `Non défini`,
      countEquipment: `Nombre d'équipement | Nombre d'équipements`,
      noData: `Aucune donnée pour la simulation`,
      noDataContact: `Aucune donnée pour le contact de la simulation`,
      noDataWorks: `Aucune donnée pour les travaux de la simulation`,
      contact: `Contact client`,
      firstname: `Prénom du client`,
      lastname: `Nom du client`,
      email: `Email du client`,
      phone: `Téléphone du client`,
      address: `Adresse`,
      cp: `Code postal`,
      city: `Ville`,
      incomes: `Revenu fiscal de référence`,
      person: `Nombre de personnes composant le ménage`,
      taxBracket: `Zone fiscale`,
      TaxBracket: {
        undefined: `Ménage non défini`,
        null: `Ménage non défini`,
        '': `Ménage non défini`,
        [TaxBracket.VERY_LOW]: `Ménage aux revenus très modestes`,
        [TaxBracket.LOW]: `Ménage aux revenus modestes`,
        [TaxBracket.MEDIUM]: `Ménage aux revenus intermédiaires`,
        [TaxBracket.HIGH]: `Ménage aux revenus supérieurs`,
      },
      ownerType: `Propriétaire`,
      OwnerType: {
        undefined: `Propriétaire non défini`,
        null: `Propriétaire non défini`,
        [OwnerType.LANDLORD]: `Propriétaire occupant`,
        [OwnerType.LESSOR]: `Propriétaire bailleur`,
        [OwnerType.TENANT]: `Locataire`,
        [OwnerType.SECOND_HOME]: `Résidence secondaire`,
      },
      powerType: `Énergie utilisée pour chauffer le bien`,
      PowerType: {
        undefined: `Énergie non définie`,
        null: `Énergie non définie`,
        [PowerType.ELECTRIC]: `Électrique`,
        [PowerType.COMBUSTIBLE]: `Autre (fuel, bois etc...)`,
      },
      date: `Date de la simulation`,
      comment: `Détail des travaux prévus`,
      results: `Résultats`,
      works: `Travaux`,
      WorksTypes: {
        undefined: `Travaux non définis`,
        [WorkType.MUR_EXT]: `Isolation des murs par l'extérieur`,
        [WorkType.MUR_INT]: `Isolation des murs par l'intérieur`,
        [WorkType.RAMPANT]: `Isolation des rampants de toiture`,
        [WorkType.TERRASSE]: `Isolation des toitures terrasses`,
        [WorkType.PAROI]: `Isolation des parois vitrées`,
        [WorkType.COMBLE]: `Isolation des combles perdus`,
        [WorkType.AUTRE]: `Autre travaux`,
      },
      WorksUnits: {
        undefined: `unité`,
        null: `unité`,
        '': `unité`,
        [WorkType.MUR_EXT]: `m²`,
        [WorkType.MUR_INT]: `m²`,
        [WorkType.RAMPANT]: `m²`,
        [WorkType.TERRASSE]: `m²`,
        [WorkType.PAROI]: `équipement | équipements`,
        [WorkType.COMBLE]: `m²`,
        [WorkType.AUTRE]: `m²`,
      },
      WorksPrefix: {
        undefined: `Quantité`,
        null: `Quantité`,
        '': `Quantité`,
        [WorkType.MUR_EXT]: `Surface`,
        [WorkType.MUR_INT]: `Surface`,
        [WorkType.RAMPANT]: `Surface`,
        [WorkType.TERRASSE]: `Surface`,
        [WorkType.PAROI]: `Nombre d'équipement | Nombre d'équipements`,
        [WorkType.COMBLE]: `Surface`,
        [WorkType.AUTRE]: `Surface`,
      },
      surface: `Surface à isoler`,
      priceEquipment: `Prix du matériel`,
      priceEquipmentPerCount: `Prix du matériel (par {unit})`,
      priceWorkforce: `Prix de la main d'œuvre`,
      priceWorkforcePerCount: `Prix de la main d'œuvre (par {unit})`,
      ceePrime: `Prime CEE estimée`,
      ceePrimeTax: `Prime CEE estimée ({name})`,
      ceeName: `Organisme proposé`,
      otherPrime: `Autre(s) prime(s) locale(s) estimée(s)`,
      otherName: `Organisme éventuel`,
      priceTotalHT: `Matériel et main d'œuvre HT`,
      priceTotalHTCount: `Matériel et main d'œuvre HT / {unit}`,
      priceTotalTVA: `TVA appliquée 5.5%`,
      priceTotalTTC: `Coût total TTC`,
      cee: `CEE par {name}`,
      noCee: `Pas de prime CEE`,
      aides: `Aides par {name}`,
      noOther: `Pas d'autre prime`,
      primeRenov: `Ma prime rénov'`,
      renov: `Ma prime rénov'`,
      noRenov: `Pas de prime rénov'`,
      total: `Total des aides`,
      resteACharge: `Reste à charge`,
      emailUser: `Envoyer un email à vous même`,
      emailContact: `Envoyer un email au contact`,
    },
    Menu: {
      account: `Mon compte`,
      logout: `Déconnexion`,
      back: `Précédent`,
      home: `Accueil`,
      newSimulation: `Simulation`,
      simulations: `Dossiers`,
      users: `Utilisateurs`,
      project: `Entreprise`,
      usage: `Utilisation`,
      cee: `CEE`,
      offline: `Hors-ligne`,
    },
    Home: {
      simulation: `Lancer une simulation`,
      simulations: `Consulter les dossiers`,
      users: `Consulter les utilisateurs`,
      project: `Consulter les données de l'entreprise`,
    },
    Images: {
      logoNav: `Logo - Retour accueil`,
      logoHome: `Logo entreprise`,
    },
    mySimulation: `Ma simulation`,
    myProject: `Mon entreprise`,
    mySimulations: `Mes dossiers`,
    theSimulations: `Les dossiers`,
    newSimulation: `Lancer une nouvelle simulation`,
    loggedAs: `Connecté en tant que {user}`,
    currentRole: `Rôle : {role}`,
    currentVersion: `Digithia - Version {version}`,
    chooseNewPassword: `Choisissez votre nouveau mot de passe`,
    validate: `Valider`,
    askResetForm: `Êtes-vous sûr de vouloir effacer ce formulaire ?`,
    askResetPassword: `Demander un nouveau mot de passe`,
    askResetPasswordMessage: `Ça arrive à tout le monde d'oublier son mot de passe. Veuillez saisir votre nom d'utilisateur ou votre adresse mail et suivez les instructions de l'email que vous recevrez.`,
    choosePasswordActivateAccount: `Choisissez votre mot de passe pour activer votre compte`,
    activateAccount: `Activer le compte`,
    reinit: `Ré-initialiser le formulaire`,
    cancel: `Annuler`,
    newProject: `Nouveau projet`,
    works: `Travaux`,
    primes: `Primes`,
    editSimulationContact: `Modifier le contact client`,
    editSimulationWorks: `Modifier les travaux`,
    editMyAccount: `Modifier mon compte`,
    deleteMyAccount: `Supprimer mon compte`,
    oldPassword: `Ancien mot de passe`,
    newPassword: `Nouveau mot de passe`,
    iAmSur: `Je suis sûr`,
    deleteAccountPassphrase: `Supprimer le compte`,
    askIfSurDeleteOwnAccount: `Vous êtes bien sûr de vouloir supprimer ce compte ? Toutes les données concernant ce compte seront supprimées et cette action est irreversible. Pour pouvoir supprimer définitivement ce compte, taper la phrase suivante :`,
    editImage: `Modifier l'image`,
    addImage: `Ajouter une image`,
    editProject: `Modification de l'entreprise`,
    editUser: `Modification de l'utilisateur`,
    askDeleteUser: `Êtes-vous sûr de vouloir supprimer cet utilisateur ?`,
    askDeleteProject: `Êtes-vous sûr de vouloir supprimer le projet ?`,
    askDeleteSimulation: `Êtes-vous sûr de vouloir supprimer ce dossier ?`,
    available: `Disponible`,
    leaveBlankToNotChange: `Laisser vide pour ne pas modifier`,
    invalidPassword: `Le mot de passe doit faire au moins 8 caractères.`,
    invalidPasswordConf: `Le mot de passe et sa confirmation sont différents.`,
    invalidForm: `Le formulaire est invalide`,
    invalidSiret: `Le numéro SIRET est invalide`,
    addReport: `Ajouter un rapport`,
    show: `Afficher`,
    report: `Rapport`,
    allAccounts: `Tous les comptes`,
    showErrors: `Afficher les erreurs`,
    validateSimulation: `Sauvegarder la simulation et générer le PDF`,
    Tooltips: {
      closeMenu: `Masquer la barre latérale`,
      home: `Retour à l'accueil`,
      myAccount: `Aller sur mon compte`,
      logout: `Se déconnnecter`,
      goBack: `Revenir une page en arrière`,
      newSimulation: `Commencer une nouvelle simulation`,
      simulations: `Consulter mes anciennes simulations`,
      users: `Consulter les utilisateurs`,
      project: `Consulter les données de l'entreprise`,
      showSimulation: `Afficher la simulation`,
      duplicateSimulation: `Créer une nouvelle simulation à partir des même données`,
      deleteSimulation: `Supprimer la simulation`,
      usage: `Consulter l'utilisation de votre forfait`,
      cee: `Consulter et gérer vos primes CEE`,
      offline: `L'application fonctionne en mode hors-ligne`,
    },
  },
}

export default messages
