
import { searchSimulation } from '@/common/util'
import { t } from '@/locales'
import { Simulation } from '@/model/Simulation'
import { PropType, Ref, ref, watch } from 'vue'
import { Toaster } from '@/common/Toaster'
import { storeSimulation, StoreActionsSimulation } from '@/store/simulation'
import SimulationSmallCard from '@/components/core/simulation/SimulationSmallCard.vue'

export default {
  components: {
    SimulationSmallCard,
  },
  props: {
    simulations: {
      type: Array as PropType<Simulation[]>,
      required: true,
    },
  },
  setup(
    props: Readonly<{
      simulations: Simulation[]
    }>,
  ) {
    const loading = ref(false)
    const elementsPerPage = 10
    const currentPage = ref(1)
    const searchedSimulations: Ref<Simulation[]> = ref([])

    watch(
      () => props.simulations,
      () => {
        searchedSimulations.value = props.simulations
      },
      { immediate: true },
    )

    const search = (value: string) => {
      searchedSimulations.value = searchSimulation(value, props.simulations)
    }

    const deleteSimulation = async (id?: number) => {
      if (confirm(t('askDeleteSimulation'))) {
        const response = await storeSimulation.dispatch(
          StoreActionsSimulation.DELETE,
          id,
        )
        Toaster.toast(response)
      }
    }

    return {
      loading,
      search,
      searchedSimulations,
      deleteSimulation,
      elementsPerPage,
      currentPage,
      t,
    }
  },
}
