
import { goTo, toHumanDate } from '@/common/util'
import { t } from '@/locales'
import { Simulation } from '@/model/Simulation'
import { PropType, SetupContext } from 'vue'

export default {
  props: {
    simulation: Object as PropType<Simulation>,
  },
  setup(
    props: Readonly<{
      simulation?: Simulation | undefined
    }>,
    context: SetupContext,
  ) {
    const toggleDelete = () => {
      context.emit('delete')
    }

    return {
      goTo,
      t,
      toggleDelete,
      toHumanDate,
    }
  },
}
