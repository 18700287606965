
import { ref, onMounted } from 'vue'
import { Toaster } from '@/common/Toaster'
import {
  storeProject,
  StoreActionsProject,
  StoreGettersProject,
} from '@/store/project'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { Project } from '@/model/Project'
import ProjectForm from '@/components/core/project/ProjectForm.vue'
import ProjectDisplay from '@/components/core/project/ProjectDisplay.vue'
import { t } from '@/locales'

export default {
  components: {
    ProjectForm,
    ProjectDisplay,
  },
  setup() {
    onMounted(async () => {
      loading.value = true
      await storeProject.dispatch(
        StoreActionsProject.GET_BY_ID,
        storeAuth.getters[StoreGettersAuth.LOGGED_USER].projectId,
      )
      loading.value = false
    })

    const loading = ref(false)
    const loadingForm = ref(false)
    const editing = ref(false)

    const toggleEditing = () => {
      editing.value = !editing.value
    }

    const updateProject = async (project: Project) => {
      loadingForm.value = true
      const response = await storeProject.dispatch(StoreActionsProject.UPDATE, {
        id: project.id,
        project: project,
      })
      loadingForm.value = false
      Toaster.toast(response)
      if (response.success) {
        editing.value = false
      }
    }

    return {
      toggleEditing,
      editing,
      loading,
      loadingForm,
      updateProject,
      storeProject,
      StoreGettersProject,
      t,
    }
  },
}
