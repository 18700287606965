
import { onMounted, Ref, ref } from 'vue'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import SimulationContactCard from '@/components/core/simulation/SimulationContactCard.vue'
import SimulationWorksCard from '@/components/core/simulation/SimulationWorksCard.vue'
import SimulationResume from '@/components/core/simulation/SimulationResume.vue'
import { t } from '@/locales'
import {
  Cee,
  Simulation,
  UpdatePrimesData,
  UpdateSimulationData,
  ALL_WORKS,
  TVA,
} from '@/model/Simulation'
import {
  clipPrimes,
  getCeePrimes,
  getRenovPrimes,
  getTaxBracket,
} from '@/common/SimulationHandler'
import { useRoute } from 'vue-router'
import { StoreActionsSimulation, storeSimulation } from '@/store/simulation'
import { StoreActionsCee, storeCee, StoreGettersCee } from '@/store/cee'

export default {
  components: {
    SimulationContactCard,
    SimulationWorksCard,
    SimulationResume,
  },
  setup() {
    const loadingForm = ref(false)
    const simulation: Ref<Simulation> = ref({ cee: {}, renov: {} })

    onMounted(async () => {
      const route = useRoute()
      const id = route.params.id

      await storeCee.dispatch(StoreActionsCee.SEARCH, {
        projectId: storeAuth.getters[StoreGettersAuth.LOGGED_USER].projectId,
      })

      if (id) {
        const response = await storeSimulation.dispatch(
          StoreActionsSimulation.GET_BY_ID,
          id,
        )
        if (response.success) {
          simulation.value = response.data
        } else {
          simulation.value = JSON.parse(
            localStorage.getItem('simulation') || '{}',
          )
        }
      } else {
        simulation.value = JSON.parse(
          localStorage.getItem('simulation') || '{}',
        )
      }

      simulation.value = clipPrimes(simulation.value)
      if (simulation.value.priceEquipment || simulation.value.priceWorkforce) {
        simulation.value.priceEquipmentPerCount = +(
          (simulation.value.priceEquipment || 0) / (simulation.value.count || 0)
        ).toFixed(2)
        simulation.value.priceWorkforcePerCount = +(
          (simulation.value.priceWorkforce || 0) / (simulation.value.count || 0)
        ).toFixed(2)
      }
      localStorage.setItem('simulation', JSON.stringify(simulation.value))
    })

    const updateCee = (event: UpdatePrimesData) => {
      simulation.value.cee[event.name] = event.value
      localStorage.setItem('simulation', JSON.stringify(simulation.value))

      simulation.value = clipPrimes(simulation.value)
    }

    const update = (event: UpdateSimulationData) => {
      simulation.value[event.name] = event.value

      if (
        (event.name === 'person' ||
          event.name === 'incomes' ||
          event.name === 'noAuto') &&
        !simulation.value.noAuto
      ) {
        const person = simulation.value.person
        const incomes = simulation.value.incomes
        const cp = simulation.value.cp || ''
        const taxBracket = getTaxBracket(incomes, person, cp)
        simulation.value.taxBracket = taxBracket
      }

      if (
        event.name === 'name' ||
        event.name === 'count' ||
        event.name === 'powerType' ||
        event.name === 'priceEquipmentPerCount' ||
        event.name === 'priceWorkforcePerCount' ||
        event.name === 'priceEquipment' ||
        event.name === 'priceWorkforce'
      ) {
        simulation.value.cee = getCeePrimes(
          simulation.value,
          storeCee.getters[StoreGettersCee.CEES],
        )
        const cees = storeCee.getters[StoreGettersCee.CEES].filter(
          (cee: Cee) => {
            return cee.works === simulation.value.name
          },
        )

        simulation.value.ceeName = cees[0]?.name
        simulation.value.date = cees[0]?.date
      }

      if (event.name === 'priceEquipmentPerCount') {
        simulation.value.priceEquipment = +(
          (simulation.value.priceEquipmentPerCount || 0) *
          (simulation.value.count || 0)
        ).toFixed(2)
      } else if (event.name === 'priceWorkforcePerCount') {
        simulation.value.priceWorkforce = +(
          (simulation.value.priceWorkforcePerCount || 0) *
          (simulation.value.count || 0)
        ).toFixed(2)
      } else if (event.name === 'priceEquipment') {
        simulation.value.priceEquipmentPerCount = +(
          (simulation.value.priceEquipment || 0) / (simulation.value.count || 0)
        ).toFixed(2)
      } else if (event.name === 'priceWorkforce') {
        simulation.value.priceWorkforcePerCount = +(
          (simulation.value.priceWorkforce || 0) / (simulation.value.count || 0)
        ).toFixed(2)
      } else if (event.name === 'count') {
        simulation.value.priceEquipment = +(
          (simulation.value.priceEquipmentPerCount || 0) *
          (simulation.value.count || 0)
        ).toFixed(2)
        simulation.value.priceWorkforce = +(
          (simulation.value.priceWorkforcePerCount || 0) *
          (simulation.value.count || 0)
        ).toFixed(2)
      }

      if (
        event.name === 'count' ||
        event.name.match(/price/) ||
        event.name === 'name' ||
        event.name === 'fixRenov' ||
        event.name === 'disableRenov'
      ) {
        const work = ALL_WORKS.find(w => w.type === simulation.value.name)

        simulation.value.ht =
          +(simulation.value.priceEquipment || 0) +
          +(simulation.value.priceWorkforce || 0)
        simulation.value.ttc = simulation.value.ht / (1 - (work?.tva || TVA))
        simulation.value.tva = simulation.value.ttc - simulation.value.ht

        simulation.value.renov = getRenovPrimes(simulation.value)
      }

      simulation.value = clipPrimes(simulation.value)

      localStorage.setItem('simulation', JSON.stringify(simulation.value))
    }

    const resetContact = () => {
      if (!confirm(t('askResetForm'))) return
      const contactKeys: (keyof Simulation)[] = [
        `firstname`,
        `lastname`,
        `email`,
        `phone`,
        `userId`,
        `date`,
        `address`,
        `cp`,
        `city`,
        `ownerType`,
        `powerType`,
        `incomes`,
        `person`,
        `noAuto`,
        `taxBracket`,
      ]

      for (const key of contactKeys) {
        simulation.value[key] = undefined as never
      }
      localStorage.setItem('simulation', JSON.stringify(simulation.value))
    }

    const resetWorks = () => {
      if (!confirm(t('askResetForm'))) return
      const worksKeys: (keyof Simulation)[] = [
        `name`,
        `count`,
        `priceEquipment`,
        `priceWorkforce`,
        `priceEquipmentPerCount`,
        `priceWorkforcePerCount`,
        `ceeName`,
        `otherPrime`,
        `otherName`,
        `emailUser`,
        `emailContact`,
        `comment`,
      ]
      for (const key of worksKeys) {
        simulation.value[key] = undefined as never
      }
      simulation.value.cee = {}
      simulation.value.renov = {}
      localStorage.setItem('simulation', JSON.stringify(simulation.value))
    }

    const updateField = ({
      name,
      value,
    }: {
      name: keyof Simulation
      value: never
    }) => {
      simulation.value[name] = value
    }

    return {
      loadingForm,
      storeAuth,
      updateField,
      StoreGettersAuth,
      resetContact,
      update,
      resetWorks,
      simulation,
      updateCee,
      t,
    }
  },
}
