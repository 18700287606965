
import { ref, onMounted } from 'vue'
import { Toaster } from '@/common/Toaster'
import { storeUser, StoreActionsUser, StoreGettersUser } from '@/store/user'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import {
  storeSimulation,
  StoreGettersSimulation,
  StoreActionsSimulation,
} from '@/store/simulation'
import { User } from '@/model/User'
import UserForm from '@/components/core/user/UserForm.vue'
import UserDisplay from '@/components/core/user/UserDisplay.vue'
import SimulationsSearch from '@/components/core/simulation/SimulationsSearch.vue'
import { goBack } from '@/common/util'
import { useRoute } from 'vue-router'
import { t } from '@/locales/index'

export default {
  components: {
    UserForm,
    UserDisplay,
    SimulationsSearch,
  },
  setup() {
    const route = useRoute()
    const loadingUser = ref(false)
    const loadingSimulation = ref(false)
    const loadingForm = ref(false)
    const editing = ref(false)
    const isAdmin = ref(storeAuth.getters[StoreGettersAuth.IS_ADMIN])
    const userId = ref(-1)

    onMounted(async () => {
      const id = route.params.id
      userId.value = +id
      loadingUser.value = true

      storeUser
        .dispatch(StoreActionsUser.GET_BY_ID, id)
        .then(() => (loadingUser.value = false))

      if (!storeSimulation.getters[StoreGettersSimulation.HAS_SIMULATIONS]) {
        loadingSimulation.value = true
        storeSimulation.dispatch(StoreActionsSimulation.GET_ALL).then(() => {
          loadingSimulation.value = false
        })
      }
    })

    const toggleEditing = () => {
      editing.value = !editing.value
    }

    const updateUser = async (user: User) => {
      loadingForm.value = true
      const response = await storeUser.dispatch(StoreActionsUser.UPDATE, {
        id: user.id,
        user: user,
      })
      loadingForm.value = false
      Toaster.toast(response)
      if (response.success) {
        editing.value = false
      }
    }

    const deleteUser = async () => {
      if (confirm(t('askDeleteUser'))) {
        const response = await storeUser.dispatch(
          StoreActionsUser.DELETE,
          storeUser.getters[StoreGettersUser.USER].id,
        )
        Toaster.toast(response)
        if (response.success) {
          goBack()
        }
      }
    }

    return {
      toggleEditing,
      editing,
      loadingUser,
      loadingSimulation,
      loadingForm,
      updateUser,
      storeUser,
      StoreGettersUser,
      deleteUser,
      isAdmin,
      storeSimulation,
      StoreGettersSimulation,
      userId,
      t,
    }
  },
}
