
import { t } from '@/locales'
import { Simulation, ALL_TAX_BRACKETS } from '@/model/Simulation'
import { PropType } from 'vue'
import { formatEuro } from '@/common/util'

export default {
  props: {
    simulation: {
      type: Object as PropType<Simulation>,
      required: true,
    },
  },
  setup() {
    return {
      t,
      ALL_TAX_BRACKETS,
      formatEuro,
    }
  },
}
