/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { environment } from '@/env'

if (process.env.NODE_ENV === 'production') {
  console.log(`register : ${environment.VUE_APP_API_URL}service-worker.js`)
  register(`${environment.VUE_APP_API_URL}service-worker.js`, {
    ready(registration) {
      console.log(
        'App is being served from cache by a service worker.',
        registration,
      )
    },
    registered(registration) {
      console.log('Service worker has been registered.', registration)
      document.dispatchEvent(
        new CustomEvent('swRegistered', { detail: registration }),
      )
    },
    cached(registration) {
      console.log('Content has been cached for offline use.', registration)
    },
    updatefound(registration) {
      console.log('New content is downloading.', registration)
    },
    updated(registration) {
      console.log('New content is available; please refresh.', registration)
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration }),
      )
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      )
      document.dispatchEvent(new CustomEvent('swOffline', { detail: true }))
    },
    error(error) {
      console.error('Error during service worker registration : ', error)
    },
  })
}
