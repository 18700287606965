import { StoreActionsAuth, StoreGettersAuth, storeAuth } from '@/store/auth'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { storeNav, NavStoreActions, NavStoreGetters } from '@/store/nav'
import { nextTick } from '@vue/runtime-core'

import Main from '@/views/Main.vue'
import ViewHome from '@/views/home/ViewHome.vue'
import ViewAccount from '@/views/home/ViewAccount.vue'
import ViewDossiers from '@/views/home/ViewDossiers.vue'
import ViewUser from '@/views/home/ViewUser.vue'
import ViewProject from '@/views/home/ViewProject.vue'
import ViewSimulation from '@/views/home/ViewSimulation.vue'
import ViewNewSimulation from '@/views/home/ViewNewSimulation.vue'
import ViewUsers from '@/views/home/ViewUsers.vue'
import ViewUsage from '@/views/home/ViewUsage.vue'
import ViewCee from '@/views/home/ViewCee.vue'
import NotFound from '@/views/home/NotFound.vue'
import Login from '@/views/auth/Login.vue'
import AskResetPassword from '@/views/auth/AskResetPassword.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import ActivateAccount from '@/views/auth/ActivateAccount.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Main,
    meta: {
      title: 'Simulation - Accueil',
    },
    children: [
      {
        path: '',
        redirect: 'home',
      },
      {
        name: 'Home',
        path: 'home',
        component: ViewHome,
      },
      {
        name: 'Account',
        path: 'account',
        component: ViewAccount,
        meta: {
          title: 'Simulation - Mon compte',
        },
      },
      {
        name: 'Dossiers',
        path: 'dossiers',
        component: ViewDossiers,
        meta: {
          title: 'Simulation - Mes dossiers',
        },
      },
      {
        name: 'User',
        path: 'users/:id',
        component: ViewUser,
        meta: {
          title: 'Simulation - Utilisateur',
        },
      },
      {
        name: 'Project',
        path: 'project',
        component: ViewProject,
        meta: {
          title: 'Simulation - Entreprise',
        },
      },
      {
        name: 'Simulation',
        path: 'simulations/:id',
        component: ViewSimulation,
        meta: {
          title: 'Simulation - Dossier',
        },
      },
      {
        name: 'NewSimulation',
        path: 'new-simulation',
        component: ViewNewSimulation,
        meta: {
          title: 'Simulation - Nouvelle simulation',
        },
      },
      {
        name: 'NewSimulationFrom',
        path: 'new-simulation/:id',
        component: ViewNewSimulation,
        meta: {
          title: 'Simulation - Nouvelle simulation',
        },
      },
      {
        name: 'Users',
        path: 'users',
        component: ViewUsers,
        meta: {
          title: 'Simulation - Utilisateurs',
        },
      },
      {
        name: 'Usage',
        path: 'project/usage',
        component: ViewUsage,
        meta: {
          title: 'Simulation - Utilisation',
        },
      },
      {
        name: 'Cee',
        path: 'project/cee',
        component: ViewCee,
        meta: {
          title: 'Simulation - CEE',
        },
      },
      {
        path: '/:catchAll(.*)',
        component: NotFound,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Simulation - Connexion',
    },
    component: Login,
  },
  {
    path: '/ask-reset-password',
    name: 'AskResetPassword',
    meta: {
      title: 'Simulation - Demande de mot de passe',
    },
    component: AskResetPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      title: 'Simulation - Nouveau mot de passe',
    },
    component: ResetPassword,
  },
  {
    meta: {
      title: 'Simulation - Activer le compte',
    },
    path: '/activate-account',
    name: 'ActivateAccount',
    component: ActivateAccount,
  },
  { path: '/:catchAll(.*)', component: Main },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (storeNav.getters[NavStoreGetters.NAV_STATE].mobile) {
    storeNav.dispatch(NavStoreActions.TOGGLE_SIDEBAR, false)
  }

  if (to.query.token) {
    storeAuth.dispatch(StoreActionsAuth.SET_TOKEN, to.query.token)
  }

  console.log('post to SW', navigator.serviceWorker?.controller?.postMessage)
  navigator.serviceWorker?.controller?.postMessage({ type: 'CHECK_UPDATE' })
  // navigator.serviceWorker
  //   .getRegistration(`${environment.VUE_APP_API_URL}service-worker.js`)
  //   .then(registration => {
  //     console.log('registration !', registration)
  //     registration?.update()
  //   })

  if (
    to.name !== 'Login' &&
    to.name !== 'AskResetPassword' &&
    !storeAuth.getters[StoreGettersAuth.IS_LOGGED]
  ) {
    next({ name: 'Login' })
  } else {
    if (
      to.name !== 'ActivateAccount' &&
      storeAuth.getters[StoreGettersAuth.LOGGED_USER].role === 10
    ) {
      next({ name: 'Login' })
    } else {
      next()
    }
  }
})

const DEFAULT_TITLE = 'Some Default Title'
router.afterEach(to => {
  nextTick(() => {
    document.title = (to.meta.title as string) || DEFAULT_TITLE
  })
})

export default router
