import { createI18n } from 'vue-i18n'
import messages from './fr'

const i18n = createI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages,
})

const loadedLanguages = ['fr']

function setI18nLanguage(lang: string) {
  i18n.global.locale = lang
  const html = document.querySelector('html')
  html?.setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang: string) {
  if (i18n.global.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  return import(`@/locales/${lang}.ts`).then(messages => {
    i18n.global.setLocaleMessage(lang, messages.default[lang])
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

export default i18n

export const t = i18n.global.t
export const tc = i18n.global.tc
