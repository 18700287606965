
import { PropType, Ref, ref, SetupContext, watch } from 'vue'
import { Inputs } from '@/common/Inputs'
import { Toaster } from '@/common/Toaster'
import { t } from '@/locales/index'
import { User, Role } from '@/model/User'

export default {
  props: {
    user: Object as PropType<User>,
    loading: Boolean,
    creation: Boolean,
    projectId: Number,
  },
  emits: ['cancel', 'validate'],
  setup(
    props: Readonly<
      {
        loading: boolean
      } & {
        user?: User
      }
    >,
    context: SetupContext,
  ) {
    const userForm: Ref<User> = ref({})

    watch(
      () => props.user,
      () => {
        userForm.value = JSON.parse(JSON.stringify(props.user || {}))
      },
      { immediate: true },
    )

    const validate = () => {
      if (Inputs.checkInputsValidity('.form-user')) {
        context.emit('validate', userForm.value)
      } else {
        Toaster.toast({
          type: 'warning',
          message: t('invalidForm'),
        })
      }
    }

    const cancel = () => {
      userForm.value = JSON.parse(JSON.stringify(props.user || {}))
      context.emit('cancel')
    }

    return { t, validate, cancel, userForm, Role }
  },
}
