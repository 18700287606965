
import { onMounted, PropType, Ref, ref, SetupContext, watch } from 'vue'
import { Inputs } from '@/common/Inputs'
import { Toaster } from '@/common/Toaster'
import { t } from '@/locales/index'
import { Project } from '@/model/Project'
import { checkSiret } from '@/common/util'
import { DigInputText } from '@digithia/input/text'

export default {
  props: {
    project: Object as PropType<Project>,
    loading: Boolean,
  },
  emits: ['cancel', 'validate'],
  setup(
    props: Readonly<
      {
        loading: boolean
      } & {
        project?: Project
      }
    >,
    context: SetupContext,
  ) {
    const projectForm: Ref<Project> = ref({})

    onMounted(() => {
      setTimeout(() => {
        const siretInput = document.querySelector('#siret') as DigInputText
        siretInput.validator = checkSiret
        siretInput.errors.validator = t('invalidSiret')
      })
    })

    watch(
      () => props.project,
      () => {
        projectForm.value = JSON.parse(JSON.stringify(props.project))
      },
      { immediate: true },
    )

    const validate = () => {
      if (Inputs.checkInputsValidity('.form-project')) {
        context.emit('validate', projectForm.value)
      } else {
        Toaster.toast({
          type: 'warning',
          message: t('invalidForm'),
        })
      }
    }

    const cancel = () => {
      projectForm.value = JSON.parse(JSON.stringify(props.project))
      context.emit('cancel')
    }

    return { t, validate, cancel, projectForm }
  },
}
