
import { t } from '@/locales'
import {
  PLAFONDS_PARIS,
  PLAFONDS_PROVINCE,
  TaxBracket,
} from '@/model/Simulation'
import { isParis } from '@/common/SimulationHandler'
import { ref, watch } from 'vue'
import { formatEuro } from '@/common/util'

export default {
  props: {
    cp: {
      type: String,
      required: true,
    },
  },
  setup(
    props: Readonly<{
      cp: string
    }>,
  ) {
    const plafonds = ref(PLAFONDS_PROVINCE)

    watch(
      () => props.cp,
      () => {
        const province = !isParis(props.cp)
        plafonds.value = province ? PLAFONDS_PROVINCE : PLAFONDS_PARIS
      },
      { immediate: true },
    )

    const getHeader = (index: number): string => {
      return index < 5 ? `${index + 1}` : 'Par personne supplémentaires'
    }

    return {
      t,
      formatEuro,
      getHeader,
      plafonds,
      TaxBracket,
    }
  },
}
