
import { onMounted, ref } from 'vue'
import { Toaster } from '@/common/Toaster'
import { storeAuth, StoreActionsAuth, StoreGettersAuth } from '@/store/auth'

import UserForm from '@/components/core/user/UserForm.vue'
import UserDisplay from '@/components/core/user/UserDisplay.vue'
import { User } from '@/model/User'
import { t } from '@/locales'
import { Inputs } from '@/common/Inputs'
import { DigInputText } from '@digithia/input/text'

export default {
  components: {
    UserForm,
    UserDisplay,
  },
  setup() {
    const loadingForm = ref(false)
    const loadingPassword = ref(false)
    const editing = ref(false)
    const editingPassword = ref(false)
    const password = ref('')
    const passwordConf = ref('')

    onMounted(async () => {
      setTimeout(() => {
        const passwordInput = document.querySelector(
          '#password',
        ) as DigInputText
        const passwordConfInput = document.querySelector(
          '#password-conf',
        ) as DigInputText
        passwordInput.validator = Inputs.checkPassword
        passwordInput.errors.validator = t('invalidPassword').toString()

        passwordConfInput.validator = (value: string): boolean => {
          return value === password.value
        }
        passwordConfInput.errors.validator = t('invalidPasswordConf').toString()
      })
    })

    const toggleEditing = () => {
      editing.value = !editing.value
    }

    const updateUser = async (user: User) => {
      loadingForm.value = true
      const response = await storeAuth.dispatch(StoreActionsAuth.UPDATE, {
        id: user.id,
        user: user,
      })
      loadingForm.value = false
      Toaster.toast(response)
      if (response.success) {
        editing.value = false
      }
    }

    const updatePassword = async () => {
      if (!Inputs.checkInputsValidity('.sub-card')) {
        Toaster.toast({
          type: 'warning',
          message: t('invalidForm'),
        })
      } else {
        loadingPassword.value = true
        const response = await storeAuth.dispatch(
          StoreActionsAuth.UPDATE_PASSWORD,
          {
            password: password.value,
          },
        )
        loadingPassword.value = false
        Toaster.toast(response)
        if (response.success) {
          editingPassword.value = false
        }
      }
    }

    return {
      toggleEditing,
      editing,
      editingPassword,
      updateUser,
      updatePassword,
      loadingForm,
      loadingPassword,
      storeAuth,
      StoreActionsAuth,
      StoreGettersAuth,
      password,
      passwordConf,
      t,
    }
  },
}
