
import { t } from '@/locales'
import { goTo, goBack } from '@/common/util'

export default {
  setup() {
    return {
      t,
      goTo,
      goBack,
    }
  },
}
