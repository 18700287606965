
import { goTo } from '@/common/util'
import { t } from '@/locales'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import {
  storeProject,
  StoreGettersProject,
  StoreActionsProject,
} from '@/store/project'
import { onMounted, ref } from 'vue'
import { Usage } from '@/model/Usage'

export default {
  setup() {
    const project = ref(storeProject.getters[StoreGettersProject.PROJECT])
    const currentUsage = ref(0)

    onMounted(async () => {
      const projectId =
        storeAuth.getters[StoreGettersAuth.LOGGED_USER].projectId

      await storeProject.dispatch(StoreActionsProject.GET_BY_ID, projectId)
      await storeProject.dispatch(StoreActionsProject.GET_USAGES, projectId)

      project.value = storeProject.getters[StoreGettersProject.PROJECT]
      const usages = storeProject.getters[StoreGettersProject.USAGES]

      const today = new Date()
      const currentMonth = today.getMonth() + 1

      currentUsage.value = usages
        .filter((u: Usage) => u.date?.split('-')[1].match(`${currentMonth}`))
        .reduce((acc: number) => {
          return acc + 1
        }, 0)
    })

    return {
      goTo,
      t,
      storeProject,
      StoreGettersProject,
      project,
      currentUsage,
    }
  },
}
