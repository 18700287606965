
import { Inputs } from '@/common/Inputs'
import { Toaster } from '@/common/Toaster'
import { StoreActionsAuth, storeAuth } from '@/store/auth'
import { ref } from 'vue'
import { t } from '@/locales'
import { goTo } from '@/common/util'

export default {
  setup() {
    const username = ref('')
    const loading = ref(false)

    const askResetPassword = async () => {
      loading.value = true
      if (!Inputs.checkInputsValidity('.ask-password-form')) {
        loading.value = false
        Toaster.toast({
          type: 'warning',
          message: t('invalidForm').toString(),
        })
        return
      }

      const response = await storeAuth.dispatch(
        StoreActionsAuth.ASK_RESET_PASSWORD,
        username.value,
      )
      Toaster.toast(response)
      loading.value = false

      if (response.success) {
        goTo('/home')
      }
    }

    const keydownAsk = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        askResetPassword()
      }
    }

    return {
      username,
      loading,
      askResetPassword,
      keydownAsk,
    }
  },
}
