import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { Cee } from '@/model/Simulation'
import { InjectionKey } from 'vue'
import { ActionTree, createStore, GetterTree, MutationTree, Store } from 'vuex'

export interface StoreStateCee {
  cee: Cee
  cees: Cee[]
}

export enum StoreGettersCee {
  CEE = 'CEE',
  CEES = 'CEES',
}

export enum StoreActionsCee {
  GET_BY_ID = 'GET_BY_ID',
  GET_ALL = 'GET_ALL',
  SEARCH = 'SEARCH',
  UPDATE = 'UPDATE',
}

export enum StoreMutationsCee {
  SET_CEE = 'SET_CEE',
  SET_CEES = 'SET_CEES',
  UPDATE_CEE = 'UPDATE_CEE',
  UPDATE_CEES = 'UPDATE_CEES',
}

const state: StoreStateCee = {
  cee: {},
  cees: [],
}

const getters: GetterTree<StoreStateCee, StoreStateCee> = {
  [StoreGettersCee.CEE](state): Cee {
    return state.cee
  },
  [StoreGettersCee.CEES](state): Cee[] {
    return state.cees
  },
}

const actions: ActionTree<StoreStateCee, StoreStateCee> = {
  async [StoreActionsCee.GET_BY_ID]({ commit }, id): Promise<ServerResponse> {
    const response = await apiHandler.get(`cees/${id}`)
    commit(StoreMutationsCee.SET_CEE, response.data)
    return response
  },
  async [StoreActionsCee.GET_ALL]({ commit }): Promise<ServerResponse> {
    const response = await apiHandler.get(`cees/`)
    commit(StoreMutationsCee.SET_CEES, response.data)
    return response
  },
  async [StoreActionsCee.SEARCH](
    { commit },
    searchdata,
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(`cees/search`, searchdata)
    commit(StoreMutationsCee.SET_CEES, response.data)
    return response
  },
  async [StoreActionsCee.UPDATE]({ commit }, cee): Promise<ServerResponse> {
    const response = await apiHandler.put(`cees`, cee)
    if (response.success) {
      commit(StoreMutationsCee.SET_CEES, response.data)
    }
    return response
  },
}

const mutations: MutationTree<StoreStateCee> = {
  [StoreMutationsCee.SET_CEES](state, cees: Cee[] = []): void {
    state.cees = cees
  },
  [StoreMutationsCee.SET_CEE](state, cee: Cee = {}): void {
    state.cee = cee
  },
  [StoreMutationsCee.UPDATE_CEE](state, cee: Cee): void {
    state.cee = cee
    state.cees = state.cees.map(c => (c.id === cee?.id ? cee : c))
  },
  [StoreMutationsCee.UPDATE_CEES](state, cees: Cee[]): void {
    for (const cee of cees) {
      state.cees = state.cees.map(c => (c.id === cee?.id ? cee : c))
    }
  },
}

export const key: InjectionKey<Store<StoreStateCee>> = Symbol(undefined)

export const storeCee = createStore<StoreStateCee>({
  state,
  mutations,
  getters,
  actions,
})
