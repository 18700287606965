
import { DigInputText } from '@digithia/input/text'
import { Inputs } from '@/common/Inputs'
import { Toaster } from '@/common/Toaster'
import { storeAuth, StoreActionsAuth, StoreGettersAuth } from '@/store/auth'
import { goTo } from '@/common/util'
import { t } from '@/locales'
import { ref, onMounted } from 'vue'

export default {
  setup() {
    const password = ref('')
    const loading = ref(false)

    onMounted(async () => {
      setTimeout(() => {
        const passwordInput = document.querySelector(
          '#password',
        ) as DigInputText
        passwordInput.validator = Inputs.checkPassword
        passwordInput.errors.validator = t('invalidPassword').toString()
      })
    })

    const activateAccount = async () => {
      loading.value = true
      if (Inputs.checkInputsValidity('.activate-account-form')) {
        const response = await storeAuth.dispatch(
          StoreActionsAuth.ACTIVATE_ACCOUNT,
          {
            userId: storeAuth.getters[StoreGettersAuth.LOGGED_USER].id,
            password: password.value,
          },
        )
        loading.value = false
        Toaster.toast(response)
        if (response.success) {
          goTo('/home')
        }
      } else {
        loading.value = false
        Toaster.toast({
          type: 'warning',
          message: t('invalidPassword').toString(),
        })
      }
    }

    const keydownActivate = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        activateAccount()
      }
    }

    return {
      password,
      loading,
      activateAccount,
      keydownActivate,
    }
  },
}
