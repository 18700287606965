
import { DigInputText } from '@digithia/input/text'
import { Toaster } from '@/common/Toaster'
import { Inputs } from '@/common/Inputs'
import { StoreActionsAuth, storeAuth } from '@/store/auth'
import { onMounted, ref } from 'vue'
import { t } from '@/locales/index'
import { goTo } from '@/common/util'

export default {
  setup() {
    const password = ref('')
    const loading = ref(false)

    onMounted(async () => {
      setTimeout(() => {
        const passwordInput = document.querySelector(
          '#password',
        ) as DigInputText
        passwordInput.validator = Inputs.checkPassword
        passwordInput.errors.validator = t('invalidPassword').toString()
      })
    })

    const resetPassword = () => {
      loading.value = true
      if (!Inputs.checkInputsValidity('.reset-password-form')) {
        loading.value = false
        Toaster.toast({
          type: 'warning',
          message: t('invalidForm').toString(),
        })
        return
      }

      storeAuth
        .dispatch(StoreActionsAuth.RESET_PASSWORD, password.value)
        .then(json => {
          loading.value = false
          Toaster.toast(json)
          if (json.success) {
            goTo('/home')
          }
        })
    }

    const keydownReset = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        resetPassword()
      }
    }

    return {
      password,
      loading,
      resetPassword,
      keydownReset,
    }
  },
}
