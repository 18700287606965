import { apiHandler, ERROR_SERVER, ServerResponse } from '@/api/ApiHandler'
import { Simulation } from '@/model/Simulation'
import { InjectionKey } from 'vue'
import { ActionTree, createStore, GetterTree, MutationTree, Store } from 'vuex'

export interface StoreStateSimulation {
  simulation: Simulation
  simulations: Simulation[]
  report: string
}

export enum StoreGettersSimulation {
  SIMULATION = 'SIMULATION',
  SIMULATIONS = 'SIMULATIONS',
  REPORT = 'REPORT',
  HAS_SIMULATIONS = 'HAS_SIMULATIONS',
}

export enum StoreActionsSimulation {
  GET_BY_ID = 'GET_BY_ID',
  GET_ALL = 'GET_ALL',
  GET_BY_USER = 'GET_BY_USER',
  SEARCH = 'SEARCH',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  GET_REPORT = 'GET_REPORT',
  RE_GENERATE = 'RE_GENERATE',
  RE_SEND = 'RE_SEND',
}

export enum StoreMutationsSimulation {
  SET_SIMULATION = 'SET_SIMULATION',
  SET_SIMULATIONS = 'SET_SIMULATIONS',
  PUSH_SIMULATION = 'PUSH_SIMULATION',
  SHIFT_SIMULATION = 'SHIFT_SIMULATION',
  UPDATE_SIMULATION = 'UPDATE_SIMULATION',
  SET_REPORT = 'SET_REPORT',
}

const state: StoreStateSimulation = {
  simulation: { renov: {}, cee: {} },
  simulations: [],
  report: '',
}

const getters: GetterTree<StoreStateSimulation, StoreStateSimulation> = {
  [StoreGettersSimulation.SIMULATION](state): Simulation {
    return state.simulation
  },
  [StoreGettersSimulation.SIMULATIONS](state): Simulation[] {
    return state.simulations.sort((a, b) => (b.id || 0) - (a.id || 0))
  },
  [StoreGettersSimulation.HAS_SIMULATIONS](state): boolean {
    return !!state.simulations.length
  },
  [StoreGettersSimulation.REPORT](state): string {
    return state.report
  },
}

const actions: ActionTree<StoreStateSimulation, StoreStateSimulation> = {
  async [StoreActionsSimulation.GET_BY_ID](
    { commit },
    id,
  ): Promise<ServerResponse> {
    const response = await apiHandler.get(`simulations/${id}`)
    commit(StoreMutationsSimulation.SET_SIMULATION, response.data)
    return response
  },
  async [StoreActionsSimulation.GET_ALL]({ commit }): Promise<ServerResponse> {
    const response = await apiHandler.get(`simulations/`)
    commit(StoreMutationsSimulation.SET_SIMULATIONS, response.data)
    return response
  },
  async [StoreActionsSimulation.SEARCH](
    { commit },
    searchdata,
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(`simulations/search`, searchdata)
    commit(StoreMutationsSimulation.SET_SIMULATIONS, response.data)
    return response
  },
  async [StoreActionsSimulation.GET_BY_USER](
    { commit },
    id,
  ): Promise<ServerResponse> {
    const response = await apiHandler.get(`users/${id}/simulations`)
    commit(StoreMutationsSimulation.SET_SIMULATIONS, response.data)
    return response
  },
  async [StoreActionsSimulation.ADD](
    { commit },
    simulation: Simulation,
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(`simulations`, simulation)
    if (response.success) {
      commit(StoreMutationsSimulation.PUSH_SIMULATION, response.data)
    }
    if (response.message === ERROR_SERVER) {
      return {
        message: `L'application est en mode hors-ligne. Votre reqûete est bien enregistrée et sera validée dès que le réseau sera à nouveau disponible`,
        code: -1,
        type: 'warning',
      }
    }
    return response
  },
  async [StoreActionsSimulation.UPDATE](
    { commit },
    { id, simulation },
  ): Promise<ServerResponse> {
    const response = await apiHandler.put(`simulations/${id}`, simulation)
    if (response.success) {
      commit(StoreMutationsSimulation.UPDATE_SIMULATION, response.data)
    }
    return response
  },
  async [StoreActionsSimulation.DELETE](
    { commit },
    id,
  ): Promise<ServerResponse> {
    const response = await apiHandler.delete(`simulations/${id}`)
    if (response.success) {
      commit(StoreMutationsSimulation.SHIFT_SIMULATION, id)
    }
    return response
  },
  async [StoreActionsSimulation.GET_REPORT](
    { commit },
    id,
  ): Promise<ServerResponse> {
    const response = await apiHandler.get(`simulations/${id}/report`)
    if (response.success) {
      commit(StoreMutationsSimulation.SET_REPORT, response.data)
    }
    return response
  },
  async [StoreActionsSimulation.RE_GENERATE](
    { commit },
    id,
  ): Promise<ServerResponse> {
    const response = await apiHandler.get(`simulations/${id}/regenerate`)
    if (response.success) {
      commit(StoreMutationsSimulation.SET_REPORT, response.data)
    }
    return response
  },
  async [StoreActionsSimulation.RE_SEND](state, id): Promise<ServerResponse> {
    const response = await apiHandler.get(`simulations/${id}/resend`)
    return response
  },
}

const mutations: MutationTree<StoreStateSimulation> = {
  [StoreMutationsSimulation.SET_SIMULATIONS](
    state,
    simulations: Simulation[] = [],
  ): void {
    state.simulations = simulations
  },
  [StoreMutationsSimulation.SET_SIMULATION](
    state,
    simulation: Simulation,
  ): void {
    state.simulation = simulation
  },
  [StoreMutationsSimulation.PUSH_SIMULATION](
    state,
    simulation: Simulation,
  ): void {
    state.simulations.push(simulation)
  },
  [StoreMutationsSimulation.SHIFT_SIMULATION](state, id: number): void {
    state.simulations = state.simulations.filter(p => p.id !== id)
  },
  [StoreMutationsSimulation.UPDATE_SIMULATION](
    state,
    simulation: Simulation,
  ): void {
    if (!simulation) return
    state.simulations = state.simulations.map(p =>
      p.id === simulation.id ? simulation : p,
    )
  },
  [StoreMutationsSimulation.SET_REPORT](state, report: string): void {
    state.report = report
  },
}

export const key: InjectionKey<Store<StoreStateSimulation>> = Symbol(undefined)

export const storeSimulation = createStore<StoreStateSimulation>({
  state,
  mutations,
  getters,
  actions,
})
