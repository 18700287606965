
import { t } from '@/locales/index'
import { extractExpiration } from '@/common/util'
import { Toaster } from '@/common/Toaster'
import { storeUser, StoreActionsUser } from '@/store/user'
import { PropType, ref } from 'vue'
import { User } from '@/model/User'

export default {
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup(
    props: Readonly<{
      user: User
    }>,
  ) {
    const loading = ref(false)

    const isLinkExpired = (link: string | undefined): boolean => {
      if (!link) return false
      const token = link.split('?token=')[1]
      const exp = extractExpiration(token)
      return !(new Date().getTime() < exp * 1000 + 60 * 60 * 10 * 1000)
    }

    const regenerateActiation = async () => {
      loading.value = true
      const response = await storeUser.dispatch(
        StoreActionsUser.REGENERATE_ACTIVATION,
        props.user.id,
      )
      loading.value = false
      Toaster.toast(response)
    }

    const copyLink = () => {
      var text = props.user.link
      navigator.clipboard.writeText(text || '').then(
        () => {
          Toaster.toast({
            type: 'success',
            message: `Lien copié dans le presse papier`,
          })
        },
        () => {
          Toaster.toast({
            type: 'error',
            message: `Impossible de copier le lien`,
          })
        },
      )
    }

    return {
      t,
      isLinkExpired,
      regenerateActiation,
      loading,
      copyLink,
    }
  },
}
