
import { PropType, ref, SetupContext } from 'vue'
import { t } from '@/locales/index'
import { Simulation, UpdateSimulationData } from '@/model/Simulation'
import SimulationContactForm from '@/components/core/simulation/SimulationContactForm.vue'

export default {
  components: {
    SimulationContactForm,
  },
  props: {
    simulation: Object as PropType<Simulation>,
    loading: Boolean,
  },
  emits: ['update', 'reset'],
  setup(
    props: Readonly<
      {
        loading: boolean
      } & {
        simulation?: Simulation
      }
    >,
    context: SetupContext,
  ) {
    const editing = ref(true)
    const loadingSimulationContact = ref(false)
    const loadingForm = ref(false)

    const updateField = (event: UpdateSimulationData) => {
      context.emit('update', event)
    }

    return {
      editing,
      updateField,
      loadingSimulationContact,
      loadingForm,
      t,
    }
  },
}
