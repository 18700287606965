
import { goTo } from '@/common/util'
import { t } from '@/locales/index'
import { PropType, ref, SetupContext } from 'vue'
import { User } from '@/model/User'
import { storeAuth, StoreGettersAuth } from '@/store/auth'

export default {
  props: {
    user: Object as PropType<User>,
  },
  emit: ['delete'],
  setup(
    props: Readonly<{
      user?: User | undefined
    }>,
    context: SetupContext,
  ) {
    const toggleDelete = () => {
      context.emit('delete', props.user?.id)
    }
    const isAdmin = ref(storeAuth.getters[StoreGettersAuth.IS_ADMIN])

    return {
      isAdmin,
      goTo,
      t,
      toggleDelete,
    }
  },
}
