
import { toHumanDate, formatEuro } from '@/common/util'
import { t, tc } from '@/locales/index'
import BaseCard from '@/components/base/BaseCard.vue'
import TablePrimes from './TablePrimes.vue'

export default {
  components: { BaseCard, TablePrimes },
  props: ['simulation'],
  setup() {
    return { t, tc, toHumanDate, formatEuro }
  },
}
