
import { onMounted, ref } from 'vue'
import { storeUser, StoreActionsUser, StoreGettersUser } from '@/store/user'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { Toaster } from '@/common/Toaster'
import UserSmallCard from '@/components/core/user/UserSmallCard.vue'
import UserForm from '@/components/core/user/UserForm.vue'
import { User } from '@/model/User'
import { searchUser } from '@/common/util'
import { t } from '@/locales'

export default {
  components: {
    UserSmallCard,
    UserForm,
  },
  setup() {
    onMounted(async () => {
      loading.value = true
      await storeUser.dispatch(StoreActionsUser.GET_ALL)
      users.value = storeUser.getters[StoreGettersUser.USERS]
      loading.value = false
    })

    const loading = ref(true)
    const loadingForm = ref(false)
    const adding = ref(false)
    const users = ref(storeUser.getters[StoreGettersUser.USERS])
    const elementsPerPage = 10
    const currentPage = ref(1)

    const toggleAdding = () => {
      adding.value = !adding.value
    }

    const addUser = async (user: User) => {
      loadingForm.value = true
      const response = await storeUser.dispatch(StoreActionsUser.ADD, {
        ...user,
        projectId: storeAuth.getters[StoreGettersAuth.LOGGED_USER].projectId,
      })
      loadingForm.value = false
      Toaster.toast(response)
      if (response.success) {
        adding.value = false
      }
    }

    const deleteUser = async (id: number) => {
      if (confirm(t('askDeleteUser'))) {
        const response = await storeUser.dispatch(StoreActionsUser.DELETE, id)
        Toaster.toast(response)
        users.value = storeUser.getters[StoreGettersUser.USERS]
      }
    }

    const search = (value: string) => {
      users.value = searchUser(value, storeUser.getters[StoreGettersUser.USERS])
    }

    return {
      loading,
      loadingForm,
      adding,
      toggleAdding,
      addUser,
      storeUser,
      StoreGettersUser,
      storeAuth,
      StoreGettersAuth,
      users,
      deleteUser,
      search,
      t,
      elementsPerPage,
      currentPage,
    }
  },
}
