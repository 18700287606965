export enum Role {
  superadmin = 1,
  admin = 2,
  user = 3,
}

export interface User {
  id?: number
  username?: string
  password?: string
  activated?: boolean
  email?: string
  role?: Role
  projectId?: number
  phone?: string
  lastname?: string
  firstname?: string
  link?: string
}

export interface TokenData {
  user?: User
  iat?: number
  exp?: number
}
