import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '@digithia/input'
import '@digithia/ui'
import i18n from './locales'

const app = createApp(App)
  .use(store)
  .use(i18n)
  .use(router)

app.config.isCustomElement = tag => tag.startsWith('dig-')

const requireComponent = require.context(
  './components/base',
  false,
  /Base[A-Z]\w+\.(vue|js)$/,
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = (fileName.split('/').pop() || '').replace(/\.\w+$/, '')
  app.component(componentName, componentConfig.default)
})

app.mount('#app')

// navigator.serviceWorker.getRegistrations().then(registrations => {
//   for (const registration of registrations) {
//     registration.unregister()
//   }
// })
