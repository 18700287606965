
import {
  ALL_WORKS,
  ALL_OWNER_TYPES,
  ALL_TAX_BRACKETS,
  ALL_POWER_TYPES,
  WorkType,
  PowerType,
  TaxBracket,
  Cee,
} from '@/model/Simulation'
import { t } from '@/locales'
import { storeCee, StoreActionsCee, StoreGettersCee } from '@/store/cee'
import { storeProject, StoreGettersProject } from '@/store/project'
import { onMounted, ref } from 'vue'
import { storeAuth, StoreGettersAuth } from '@/store/auth'

export default {
  components: {},
  setup() {
    const loading = ref(false)

    onMounted(async () => {
      loading.value = true
      await storeCee.dispatch(StoreActionsCee.SEARCH, {
        projectId: storeAuth.getters[StoreGettersAuth.LOGGED_USER].projectId,
      })
      loading.value = false
    })

    const getCeePrice = (
      works: WorkType,
      power: PowerType,
      taxBracket: TaxBracket,
    ): number => {
      const cees = storeCee.getters[StoreGettersCee.CEES].filter(
        (cee: Cee) =>
          cee.works === works &&
          cee.power === power &&
          cee.taxBracket === taxBracket,
      )
      return cees[0]?.price || null
    }

    const getCeeName = (works: WorkType): number => {
      const cees = storeCee.getters[StoreGettersCee.CEES].filter(
        (cee: Cee) => cee.works === works,
      )
      return cees[0]?.name || ''
    }

    const getCeeDate = (works: WorkType): number => {
      const cees = storeCee.getters[StoreGettersCee.CEES].filter(
        (cee: Cee) => cee.works === works,
      )
      return cees[0]?.date || ''
    }

    const update = async (
      field: string,
      works: WorkType,
      power: PowerType,
      taxBracket: TaxBracket,
      value: number,
    ) => {
      await storeCee.dispatch(StoreActionsCee.UPDATE, {
        projectId: storeProject.getters[StoreGettersProject.PROJECT].id,
        field,
        works,
        power,
        taxBracket,
        value: value,
      })
    }

    const updateMultiple = async (
      field: string,
      works: WorkType,
      value: number,
    ) => {
      await storeCee.dispatch(StoreActionsCee.UPDATE, {
        projectId: storeProject.getters[StoreGettersProject.PROJECT].id,
        field,
        works,
        value,
      })
    }

    return {
      t,
      ALL_WORKS,
      ALL_OWNER_TYPES,
      ALL_TAX_BRACKETS,
      ALL_POWER_TYPES,
      loading,
      getCeePrice,
      update,
      updateMultiple,
      getCeeName,
      getCeeDate,
    }
  },
}
