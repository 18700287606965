
import { goTo } from '@/common/util'
import { t } from '@/locales'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { storeProject, StoreGettersProject } from '@/store/project'
import { ref } from 'vue'

export default {
  setup() {
    const isAdmin = ref(storeAuth.getters[StoreGettersAuth.IS_ADMIN])
    const loggedUser = storeAuth.getters[StoreGettersAuth.LOGGED_USER]
    const name = `${loggedUser.firstname || ''} ${loggedUser.lastname || ''}`

    return {
      goTo,
      isAdmin,
      name,
      t,
      storeProject,
      StoreGettersProject,
    }
  },
}
