import { apiHandler, ServerResponse } from '@/api/ApiHandler'
import { extractExpiration } from '@/common/util'
import { Role, TokenData, User } from '@/model/User'
import { InjectionKey } from 'vue'
import { ActionTree, createStore, GetterTree, MutationTree, Store } from 'vuex'

const getTokendata = (token: string): TokenData => {
  const base64Url = token.split('.')[1]
  if (!base64Url) return {}
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}
export interface StoreStateAuth {
  user: User
  users: User[]
}

export enum StoreGettersAuth {
  LOGGED_USER = 'LOGGED_USER',
  TOKEN = 'TOKEN',
  IS_LOGGED = 'IS_LOGGED',
  IS_ADMIN = 'IS_ADMIN',
  USER = 'USER',
  USERS = 'USERS',
}

export enum StoreActionsAuth {
  LOGOUT = 'LOGOUT',
  LOGIN = 'LOGIN',
  DELETE_USER = 'DELETE_USER',
  GET_BY_ID = 'GET_BY_ID',
  SEARCH = 'SEARCH',
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
  ASK_RESET_PASSWORD = 'ASK_RESET_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  SET_TOKEN = 'SET_TOKEN',
  UPDATE = 'UPDATE',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
}

export enum StoreMutationsAuth {
  SET_TOKEN = 'SET_TOKEN',
  SET_USER = 'SET_USER',
  SET_USERS = 'SET_USERS',
  SET_LOGGED_USER = 'SET_LOGGED_USER',
  REMOVE_USER = 'REMOVE_USER',
  PUSH_USER = 'PUSH_USER',
}

const state: StoreStateAuth = {
  user: {},
  users: [],
}

const getters: GetterTree<StoreStateAuth, StoreStateAuth> = {
  [StoreGettersAuth.LOGGED_USER](): User {
    return JSON.parse(localStorage.getItem('loggedUser') || '{}')
  },
  [StoreGettersAuth.USER](state): User {
    return state.user
  },
  [StoreGettersAuth.USERS](state): User[] {
    return state.users
  },
  [StoreGettersAuth.IS_LOGGED](): boolean {
    const token = localStorage.getItem('token') || ''
    if (!token) {
      return false
    }
    const creationDate = extractExpiration(token)
    return new Date().getTime() < creationDate * 1000 + 60 * 60 * 10 * 1000
  },
  [StoreGettersAuth.IS_ADMIN](state, getters): boolean {
    return (
      getters[StoreGettersAuth.LOGGED_USER].role === Role.admin ||
      getters[StoreGettersAuth.LOGGED_USER].role === Role.superadmin
    )
  },
  [StoreGettersAuth.TOKEN](): string {
    return localStorage.getItem('token') || ''
  },
}

const actions: ActionTree<StoreStateAuth, StoreStateAuth> = {
  async [StoreActionsAuth.LOGIN](
    { commit },
    { username, password },
  ): Promise<ServerResponse> {
    const response = await apiHandler.post('users/login', {
      username,
      password,
    })
    if (response.success) {
      const token = response.data
      commit(StoreMutationsAuth.SET_TOKEN, token)
      const resUser = await apiHandler.get(
        `users/${getTokendata(token).user?.id}`,
      )
      commit(StoreMutationsAuth.SET_LOGGED_USER, resUser.data)
    }
    return response
  },
  async [StoreActionsAuth.UPDATE](
    { commit },
    { id, user },
  ): Promise<ServerResponse> {
    const response = await apiHandler.put(`users/${id}`, user)
    if (response.success) {
      commit(StoreMutationsAuth.SET_LOGGED_USER, response.data)
    }
    return response
  },
  async [StoreActionsAuth.UPDATE_PASSWORD](
    { getters },
    { password },
  ): Promise<ServerResponse> {
    const response = await apiHandler.put(
      `users/${getters[StoreGettersAuth.LOGGED_USER].id}/password`,
      { password },
    )
    return response
  },
  async [StoreActionsAuth.ACTIVATE_ACCOUNT](
    { commit },
    { userId, password },
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(`users/${userId}/activate-account`, {
      password,
    })
    if (response.success) {
      const token = response.data
      commit(StoreMutationsAuth.SET_TOKEN, token)
      const resUser = await apiHandler.get(
        `users/${getTokendata(token).user?.id}`,
      )
      commit(StoreMutationsAuth.SET_LOGGED_USER, resUser.data)
    }
    return response
  },
  async [StoreActionsAuth.ASK_RESET_PASSWORD](
    state,
    username,
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(`users/ask-reset-password`, {
      username,
    })
    return response
  },
  async [StoreActionsAuth.RESET_PASSWORD](
    { commit, getters },
    password,
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(
      `users/${getters[StoreGettersAuth.LOGGED_USER].id}/reset-password`,
      {
        password,
      },
    )
    if (response.success) {
      const token = response.data
      commit(StoreMutationsAuth.SET_TOKEN, token)
      const resUser = await apiHandler.get(
        `users/${getTokendata(token).user?.id}`,
      )
      commit(StoreMutationsAuth.SET_LOGGED_USER, resUser.data)
    }
    return response
  },
  [StoreActionsAuth.LOGOUT]({ commit }): void {
    commit(StoreMutationsAuth.SET_TOKEN, '')
  },
  [StoreActionsAuth.SET_TOKEN]({ commit }, token: string): void {
    commit(StoreMutationsAuth.SET_TOKEN, token)
    commit(StoreMutationsAuth.SET_LOGGED_USER, getTokendata(token).user)
  },
  async [StoreActionsAuth.DELETE_USER](
    { commit },
    id: number,
  ): Promise<ServerResponse> {
    const response = await apiHandler.delete(
      'account/accountManager.php?id=' + id,
    )
    commit(StoreMutationsAuth.REMOVE_USER, id)
    return response
  },
  async [StoreActionsAuth.SEARCH](
    { commit },
    data = {},
  ): Promise<ServerResponse> {
    const response = await apiHandler.post(
      'account/accountManager.php?search=true',
      data,
    )
    commit(StoreMutationsAuth.SET_USERS, response.accounts)
    return response
  },
  async [StoreActionsAuth.GET_BY_ID]({ commit }, id): Promise<ServerResponse> {
    const response = await apiHandler.post(
      'account/accountManager.php?search=true',
      { id: id },
    )
    commit(StoreMutationsAuth.SET_USER, response.accounts[0])
    return response
  },
}

const mutations: MutationTree<StoreStateAuth> = {
  [StoreMutationsAuth.REMOVE_USER](state, id: number): void {
    state.users = state.users.filter(u => u.id !== id)
  },
  [StoreMutationsAuth.SET_LOGGED_USER](state, user: User): void {
    localStorage.setItem('loggedUser', JSON.stringify(user || {}))
  },
  [StoreMutationsAuth.SET_TOKEN](state, token: string): void {
    localStorage.setItem('token', token || '')
  },
  [StoreMutationsAuth.SET_USER](state, user: User): void {
    state.user = user
  },
  [StoreMutationsAuth.SET_USERS](state, users: User[]): void {
    state.users = users
  },
  [StoreMutationsAuth.PUSH_USER](state, user: User = {}): void {
    state.users.push(user)
  },
}

export const key: InjectionKey<Store<StoreStateAuth>> = Symbol(undefined)

export const storeAuth = createStore<StoreStateAuth>({
  state,
  mutations,
  getters,
  actions,
})
