
import { PropType, Ref, ref, SetupContext, watch } from 'vue'
import { t } from '@/locales/index'
import {
  Simulation,
  WorkType,
  ALL_TAX_BRACKETS,
  ALL_WORKS,
} from '@/model/Simulation'

export default {
  props: {
    simulation: Object as PropType<Simulation>,
    loading: Boolean,
  },
  emits: ['update', 'reset', 'updateCee'],
  setup(
    props: Readonly<
      {
        loading: boolean
      } & {
        simulation?: Simulation
      }
    >,
    context: SetupContext,
  ) {
    const simulationForm: Ref<Simulation> = ref({ cee: {}, renov: {} })

    watch(
      () => props.simulation,
      () => {
        simulationForm.value = JSON.parse(
          JSON.stringify(props.simulation || {}),
        )
      },
      { immediate: true, deep: true },
    )

    const updateField = (name: string, value: string | number | boolean) => {
      if (name === 'name' && (!value || props.simulation?.name === value)) {
        return
      }
      context.emit('update', { name, value })
    }

    const updateCee = (name: string, value: number) => {
      context.emit('updateCee', { name, value })
    }

    const reset = () => {
      context.emit('reset')
    }

    const ALL_CEE_KEYS: (keyof Simulation)[] = [
      `cee${ALL_TAX_BRACKETS[0]}` as keyof Simulation,
      `cee${ALL_TAX_BRACKETS[1]}` as keyof Simulation,
      `cee${ALL_TAX_BRACKETS[2]}` as keyof Simulation,
      `cee${ALL_TAX_BRACKETS[3]}` as keyof Simulation,
    ]

    return {
      t,
      updateField,
      updateCee,
      simulationForm,
      WorkType,
      reset,
      ALL_TAX_BRACKETS,
      ALL_CEE_KEYS,
      ALL_WORKS,
    }
  },
}
