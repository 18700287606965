
import {
  storeSimulation,
  StoreGettersSimulation,
  StoreActionsSimulation,
} from '@/store/simulation'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { onMounted, ref } from 'vue'
import { goTo } from '@/common/util'
import SimulationsSearch from '@/components/core/simulation/SimulationsSearch.vue'
import { t } from '@/locales/index'

export default {
  components: {
    SimulationsSearch,
  },
  setup() {
    const loading = ref(true)
    const isAdmin = ref(storeAuth.getters[StoreGettersAuth.IS_ADMIN])

    onMounted(async () => {
      loading.value = true
      await storeSimulation.dispatch(StoreActionsSimulation.GET_ALL)
      loading.value = false
    })

    return {
      storeSimulation,
      StoreGettersSimulation,
      loading,
      goTo,
      t,
      isAdmin,
    }
  },
}
