
import { Inputs } from '@/common/Inputs'
import { Toaster } from '@/common/Toaster'
import { StoreActionsAuth, StoreGettersAuth, storeAuth } from '@/store/auth'
import { storeProject, StoreActionsProject } from '@/store/project'
import { ref } from 'vue'
import { t } from '@/locales/index'
import { goTo } from '@/common/util'

export default {
  setup() {
    const username = ref('')
    const password = ref('')
    const loading = ref(false)

    const login = () => {
      loading.value = true
      if (!Inputs.checkInputsValidity('.login-form')) {
        loading.value = false
        Toaster.toast({
          type: 'warning',
          message: t('invalidForm').toString(),
        })
        return
      }

      storeAuth
        .dispatch(StoreActionsAuth.LOGIN, {
          username: username.value,
          password: password.value,
        })
        .then(async json => {
          Toaster.toast(json)
          loading.value = false
          if (json.success) {
            await storeProject.dispatch(
              StoreActionsProject.GET_BY_ID,
              storeAuth.getters[StoreGettersAuth.LOGGED_USER].projectId,
            )
            goTo('/home')
          }
        })
    }

    const keydownLogin = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        login()
      }
    }

    return {
      username,
      password,
      loading,
      login,
      keydownLogin,
    }
  },
}
